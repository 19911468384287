export default {
  'request.10000': 'Invalid company',
  'request.10001': 'Invalid username or password',
  'request.10002': 'Trial license expired',
  'request.10003': 'Incorrect verification code input',
  'request.10004': 'The enterprise name already exists, please enter again',
  'request.10005':
    'The mobile phone number has been registered, please do not repeat the operation',
  'request.10006': 'Same Password',
  'request.10007': 'Not Exit User',
  'request.10008': 'Wrong Original Password',
  'request.10009': 'You have logged in. You cannot log in again. Please try again later',
  'request.10010':
    'The number of currently logged-in users has exceeded the maximum number of users allowed by the system',
  'request.10011': 'The mailbox has been registered, please do not repeat the operation',
  'request.10012': 'The equipment number has been registered, please check',
  'request.10013': 'Please try again in 1 min.',
  'request.10014':
    'System activation failed. Please right-click the verification code to obtain the system activation code',
  'request.10015':
    'The current user has not been assigned any permissions. Please contact the administrator to set permissions before logging in to the system',
  'request.10016': 'Please insert the dongle',
};
