export default {
  'menu.home': 'Home',
  'menu.dashboard': 'Dashboard',

  'menu.basic': 'Basic Operation',
  'menu.basic.read': 'Read Data',
  'menu.basic.history': 'History Data',
  'menu.basic.report': 'Patrol Report',
  'menu.basic.detail': 'Report Detail',
  'menu.basic.chart': 'Statistic Chart',
  'menu.basic.alarm': 'Alarm Data',
  'menu.basic.omit': 'Omission Data',
  'menu.basic.raw': 'Original Data',
  'menu.basic.calendar': 'Patrol Calendar',
  'menu.basic.log': 'Log Information',
  'menu.basic.realmap': 'Real-time Monitoring',
  'menu.basic.attendance': 'Attendance data',

  'menu.setup': 'Patrol Setup',
  'menu.setup.dept': 'Company Setup',
  'menu.setup.event': 'Event Setup',
  'menu.setup.guard': 'Guard Setup',
  'menu.setup.site': 'Checkpoint Setup',
  'menu.setup.plan': 'Plan Setup',
  'menu.setup.book': 'Contact Setup',
  'menu.setup.receipt': 'Receipt Setup',
  'menu.setup.down': 'Download Data',
  'menu.setup.downparam': 'Download Data',
  'menu.setup.content': 'Content Setup',
  'menu.setup.voice': 'Voice Setup',
  'menu.setup.talk': 'Walkie-talkie Setup',
  'menu.setup.duty': 'Attendance Data Download',

  'menu.data': 'Data Maintenance',
  'menu.data.device': 'Device List',
  'menu.data.devices': 'Device Settings',
  'menu.data.system': 'System Settings',
  'menu.data.role': 'Permission Setup',
  'menu.data.user': 'Operator Setup',
};
