import React from 'react';
import { getMenu, getSysTime } from '@/services/menu';
import * as Icons from '@ant-design/icons';

const toHump = (name) => name.replace(/-(\w)/g, (_, letter) => letter.toUpperCase());

const addIcon2Menu = (menu) => {
  menu.forEach((item) => {
    const { icon } = item;
    if (icon) {
      const iconName = toHump(icon.replace(icon[0], icon[0].toUpperCase()));
      const newIcon = Icons[icon] || Icons[''.concat(iconName, 'Outlined')];
      if (newIcon) {
        try {
          // eslint-disable-next-line no-param-reassign
          item.icon = React.createElement(newIcon);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('failed parse menu icon', err, icon);
        }
      }
    }
  });

  return menu;
};

const MenuModel = {
  namespace: 'menu',
  state: {
    menuData: [],
    systime: '',
  },
  effects: {
    *fetchMenu(_, { call, put }) {
      const response = yield call(getMenu);
      yield put({
        type: 'saveMenu',
        payload: addIcon2Menu(response),
      });
    },
    *fetchSysTime(_, { call, put }) {
      const response = yield call(getSysTime);
      yield put({
        type: 'timing',
        payload: response,
      });
    },
  },
  reducers: {
    saveMenu(state, action) {
      return { ...state, menuData: action.payload || [] };
    },
    timing(state, action) {
      return {
        ...state,
        systime: action.payload.content,
      };
    },
  },
};
export default MenuModel;
