export default {
  'system.title': 'Configuration des paramètres système',
  'system.event': 'Fonction événement',
  'system.event.des': "La fonction d'événement est-elle prise en charge",
  'system.plan': 'Régime spécial',
  'system.plan.des':
    'Un plan qui peut être exécuté périodiquement, tel que quotidien, hebdomadaire, mensuel',
  'system.patroltime': 'Temps de patrouille',
  'system.patroltime.des':
    'Si le temps de patrouille total prévu de chaque cercle est affiché dans le rapport de patrouille',
  'system.remaintime': 'Temps restant',
  'system.remaintime.des':
    "Afficher l'heure d'inspection du même point d'inspection dans la période de temps planifiée dans le rapport d'inspection",
  'system.Sequence': 'Patrouille de séquence',
  'system.Sequence.des':
    "S'il faut spécifier que chaque point de contrôle du plan doit être inspecté dans l'ordre défini dans le plan",
  'system.dst': "Heure d'été",
  'system.dst.des':
    "Que vous utilisiez l'heure d'été, l'heure liée au plan sera effective le lendemain",
  'system.timeformate': 'Format de date',
  'system.timeformate.des': 'Format de date affiché par le système',
  'system.database.period': 'Cycle de sauvegarde de la base de données',
  'system.database.period.des': 'Fréquence des sauvegardes de la base de données en jours',
  'system.database.time': 'Temps de sauvegarde de la base de données',
  'system.database.time.des':
    'Veuillez essayer de sélectionner le temps de pointe faible pour le téléchargement des données',
  'system.database.path': 'Chemin de sauvegarde de la base de données',
  'system.database.path.des':
    "Saisissez le chemin de sauvegarde de la base de données, par exemple: D:\backup. Si le chemin est vide, la base de données n'est pas sauvegardée",
  'system.save': 'Sauver',
  'system.enable': 'Permettre',
  'system.disable': 'Désactiver',
  'system.notification.prompt': 'Rapide',
  'system.notification.success': 'Sauvegarde réussie',
};
