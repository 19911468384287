export default {
  'alarm.notification.title': '報警提醒',
  'alarm.notification.desc': '收到 {count} 條報警數據，請注意處理...',

  'alarm.notification.view': '查看詳情',
  'alarm.notification.ignore': '忽略報警',

  'alarm.notification.list': '待處理報警',
  'alarm.notification.readAll': '全部處理',
  'alarm.notification.readAll.confirm': '是否處理全部報警？',

  'alarm.notification.read.title': '提示',
  'alarm.notification.read.done': '報警已標記為處理',
  'alarm.notification.read.error': '處理報警時發生錯誤',

  'alarm.manual': '手動報警',
  'alarm.low': '低電報警',
  'alarm.omit': '漏檢報警',
  'alarm.tilt': '傾斜報警',
  'alarm.motionless': '靜止報警',
  'alarm.collision': '摔碰報警',
  'alarm.dismantle': '開殼報警',

  'alarm.choice.no': '無報警功能',
  'alarm.choice.web': '僅網頁端報警',
  'alarm.choice.app': '僅手持端報警',
  'alarm.choice.all': '網頁及手持端均報警',
  'alarm.notification.title.desc': '是否在網頁端或手持端包含報警數據提醒功能',
};
