export default {
  'plan.title': 'Настройка графика',
  'plan.title.special': 'Настройка специального графика',
  'plan.btn.add': 'Добавить график',
  'plan.btn.addTemporary': 'Добавить временный план',
  'plan.btn.bind': 'Привязать устройство',
  'plan.btn.copy': 'Копировать план',
  'plan.btn.paste': 'Вставить план',
  'plan.btn.export': 'План экспорта',
  'plan.btn.batch': 'Расписание пакетов',
  'plan.btn.addshift': 'Добавить смену',
  'plan.btn.delshift': 'Удалить расписание',
  'plan.btn.guard': 'Настройка персонала',
  'plan.btn.addrest': 'Добавить выходной',
  'plan.btn.Manu': 'Ручная привязка',
  'plan.modal.label.next': 'следующий день',
  'plan.modal.label.onetime': 'Один раз для проверки (минуты)',
  'plan.modal.label.resttime': 'Каждое время отдыха (минуты)',
  'plan.modal.label.no': '№ 1',
  'plan.modal.label.day': 'день',
  'plan.modal.label.weekrest': 'Еженедельный выходной день',
  'plan.modal.label.specialrest': 'Особый день отдыха',
  'plan.modal.label.period': 'Период проверки',
  'plan.modal.label.unit': 'Ед. изм',
  'plan.modal.label.plantimes': 'планировать время',
  'plan.modal.label.times': 'частота',
  'plan.modal.label.cost': 'Время за круг',
  'plan.modal.label.interval': 'Интервал между двумя кругами',
  'plan.modal.label.month': 'месяц',
  'plan.modal.label.week': 'неделя',
  'plan.modal.label.minute': 'минута',
  'plan.modal.label.hour': 'час',
  'plan.modal.label.days': 'Дни',
  'plan.table.schedule': 'расписание',
  'plan.table.restday': 'день отдыха',
  'plan.table.monday': 'Понедельник',
  'plan.table.tuesday': 'Вторник',
  'plan.table.wednesday': 'Среда',
  'plan.table.thursday': 'Четверг',
  'plan.table.friday': 'пятница',
  'plan.table.saturday': 'Суббота',
  'plan.table.sunday': 'Воскресенье',
  'plan.table.type': 'тип плана',
  'plan.table.per': 'на',
  'план.таблица.месяц': 'месяц',
  'plan.table.patrol': 'Патруль',
  'plan.tab.plan': 'Обычный план',
  'plan.tab.special': 'Планирование по времени',
  'plan.tab.schedule': 'График проверок',
  'plan.step.basic': 'Основные настройки информации',
  'plan.step.site': 'Настройки сайта проверки',
  'plan.step.shift': 'Настройка графика проверок',
  'plan.step.next': 'Следующий шаг',
  'plan.step.prev': 'Предыдущий шаг',
  'plan.step.submit': 'Отправить',
  'plan.step.rest': 'настройка дня отдыха',
  'plan.transfer.site.select': 'Выбранный сайт',
  'plan.transfer.site.unselect': 'Сайт будет выбран',
  'plan.input.plan.placeholder': 'Пожалуйста, введите название плана',
  'plan.notification.description.save.success': 'план успешно сохранен',
  'plan.notification.description.sitefirst': 'Сначала укажите место проверки',
  'plan.notification.description.schedulefirst': 'Сначала установите расписание проверок',
  'plan.notification.description.exitplan':
    'В этом плане уже созданы данные за текущий день. Нужно ли перезаписывать данные за текущий день и заново создавать данные?',
  'plan.notification.description.than24':
    'Время одной проверки не может превышать 24 часов, сбросьте его',
  'plan.notification.description.repeat': 'Период смены перекрывается, сбросьте его',
  'plan.notification.description.date': 'время окончания должно превышать время начала',
  'plan.notification.description.exitdate':
    'Выбранная дата уже существует, пожалуйста, выберите еще раз',
  'plan.notification.description.exitdevice':
    'Текущее устройство уже существует, не добавляйте его снова',
  'plan.notification.description.appuser': 'Персонал должен быть указан в графике проверки',
  'plan.notification.description.copy.success': 'План копирования выполнен успешно',
  'plan.notification.description.copyfirst': 'Сначала скопируйте план',
  'plan.notification.description.selfirst': 'Пожалуйста, сначала выберите план для копирования',
  'plan.notification.description.selone': 'Пожалуйста, выберите запись для операции',
  'plan.notification.description.patrolone':
    'Количество проверок однократно, а интервал должен быть установлен равным нулю',
  'plan.Modal.confirm.paste':
    'Вы уверены, что хотите вставить скопированный план в текущий регион? ',
  'plan.Modal.confirm.restday': 'Сначала выберите специальный день отдыха',
  'plan.shift.day.wrong':
    'ежедневная программа может быть создана максимум 31 день. Прошу вас вернуться в период патрулирования!',
  'plan.shift.week.wrong':
    'еженедельная программа может быть создана максимум четыре недели. Прошу вас вернуться в период патрулирования!',
  'plan.shift.month.wrong':
    'месячный план может быть создан не более чем за два месяца. Прошу вас вернуться в период патрулирования!',
  'plan.rest.wrong': 'Не удаётся установить все выходные дни, Перезагрузите',
};
