export default {
  'system.title': 'System Settings',
  'system.event': 'Event Func',
  'system.event.des': 'Is event function supported',
  'system.plan': 'Special Plan',
  'system.plan.des': 'A plan that can be executed periodically, such as daily, weekly, monthly',
  'system.patroltime': 'Patrol Time',
  'system.patroltime.des':
    'Whether the total planned patrol time of each circle is displayed in the patrol Report',
  'system.remaintime': 'Remain Time',
  'system.remaintime.des':
    'Display the inspection time of the same inspection point in the planned time period in the inspection report',
  'system.Sequence': 'Sequence Patrol',
  'system.Sequence.des':
    'Whether to specify that each checkpoint in the plan must be inspected in the order set in the plan',
  'system.dst': 'Daylight Saving Time',
  'system.dst.des': 'Wether use DST, plan related time will be effective in the next day',
  'system.timeformate': 'Date Format',
  'system.timeformate.des': 'Date format displayed by the system',
  'system.database.period': 'Database backup cycle',
  'system.database.period.des': 'How often is the database backed up, unit: day',
  'system.database.time': 'Database backup time',
  'system.database.time.des': 'Please try to select the low peak time for data uploading',
  'system.database.path': 'Database backup path',
  'system.database.path.des':
    'Please enter the database backup path, for example: d:\\backup. If the path is empty, the database will not be backed up',
  'system.save': 'Save',
  'system.enable': 'Enable',
  'system.disable': 'Disable',
  'system.notification.prompt': 'Prompt',
  'system.notification.success': 'Save Successfully',
};
