export default {
  'system.title': '系統參數設置',
  'system.event': '事件功能',
  'system.event.des': '是否支持事件功能',
  'system.plan': '按次計劃',
  'system.plan.des': '能夠周期性執行的計劃，例如每天、每周、每月執行',
  'system.patroltime': '巡檢用時',
  'system.patroltime.des': '是否在巡檢報告中顯示每圈計劃巡檢總時間',
  'system.remaintime': '停留時間',
  'system.remaintime.des': '是否在巡檢報告中顯示每圈計劃巡檢總時間',
  'system.Sequence': '順序巡檢',
  'system.Sequence.des': '是否指定計劃內的每個巡檢點必須按照計劃中設置的順序進行巡檢',
  'system.timeformate': '日期格式',
  'system.timeformate.des': '系統顯示的日期格式',
  'system.dst': '夏令時',
  'system.dst.des': '是否啓用夏令時，更改後計劃數據相關時間將從次日起生效',
  'system.database.period': '數據庫備份周期',
  'system.database.period.des': '多久對數據庫進行備份一次，單位：天',
  'system.database.time': '數據庫備份時間',
  'system.database.time.des': '請盡量選擇在數據上傳低峰時間',
  'system.database.path': '數據庫備份路徑',
  'system.database.path.des': '請輸入數據庫備份路徑，例如：D:\\backup，路徑為空則代表不備份數據庫',
  'system.save': '保存設置',
  'system.enable': '啓用',
  'system.disable': '關閉',
  'system.notification.prompt': '提示',
  'system.notification.success': '保存成功！',
};
