export default {
  'content.title': '巡检内容',
  'content.btn.add': '增加内容',
  'content.btn.site': '选择地点',
  'content.btn.choice': '添加选项',
  'content.label.choice': '选项内容',
  'content.input.content.placeholder': '请输入内容标题',
  'content.input.des.placeholder': '请输入内容描述',
  'content.input.choice.placeholder': '请输入选项内容',
  'content.notification.description': '该选项内容已存在，不能重复添加',
  'content.notification.choice.blank': '提交方式为单选或者多选时，必须输入巡检内容！',
};
