export default {
  'role.title': '角色信息',
  'role.btn.add': '增加角色',
  'role.btn.save': '保存角色',
  'role.btn.home': '返回首頁',
  'role.table.name': '角色名稱',
  'role.table.operate': '操作',
  'role.table.edit': '編輯',
  'role.input.name.placeholder': '請輸入角色名稱',
  'role.notification.module.description': '請選擇模塊信息',
  'role.notification.role.description': '請選擇角色信息',
};
