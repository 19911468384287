export default {
  'role.title': 'Rôle',
  'role.btn.add': 'Ajouter un rôle',
  'role.btn.save': 'Enregistrer le rôle',
  'role.btn.home': 'Rentrer à la maison',
  'role.table.name': 'Nom de rôle',
  'role.table.operate': 'Fonctionner',
  'role.table.edit': 'Éditer',
  'role.input.name.placeholder': 'Veuillez saisir le nom du rôle',
  'role.notification.module.description': "Veuillez d'abord sélectionner le module",
  'role.notification.role.description': "Veuillez d'abord sélectionner le rôle",
};
