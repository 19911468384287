export default {
  'login.title': 'Welcome',
  'login.subtitle': 'Intelligent Guard Patrol System',
  'login.type.login': 'Login',
  'login.type.trial': 'Trial',
  'login.companyCode': 'Company Code',
  'login.companyCode.required': 'Company Code is required',
  'login.userName': 'Username',
  'login.userName.required': 'Username is required',
  'login.password': 'Password',
  'login.password.required': 'Password is required',
  'login.remember': 'Remember Password',
  'login.captcha': 'Captcha',
  'login.captcha.captchaRequired': 'Captcha is required',
  'login.guest': 'Guest',
  'login.submit': 'Login',
  'login.submitapplicaiton': 'Submit',
  'login.captcha.required': 'Please finish man-machine verification',
  'login.trial.companyName': 'Company Name',
  'login.trial.companyName.required': 'Input Company Name',
  'login.trial.equipment.read': 'Read',
  'login.trial.equipment': 'Device No. ',
  'login.trial.equipment.required': 'Read Device No.',
  'login.trial.contacts': 'Contact Name',
  'login.trial.contacts.required': 'Input Contact Name',
  'login.trial.contacts.phone': 'Phone',
  'login.trial.contacts.phone.required': 'Input Phone',
  'login.trial.captcha': 'Verification Code',
  'login.trial.captcha.required': 'Input Verification Code',
  'login.trial.captcha.read': 'Send',
  'login.trial.city': 'City',
  'login.trial.address.en': 'Address',
  'login.trial.countryCode': 'Country Code',
  'login.trial.timeZone': 'Time Zone',
  'login.trial.city.required': 'Input City',
  'login.trial.address': 'Company Address',
  'login.trial.address.required': 'Input Company Address',
  'login.trial.success': 'Application is successful',
  'login.trial.contacts.email': 'Email',
  'login.trial.contacts.email.required': 'Input Email',
  'login.trial.alarm.mobile':
    'The verification code has been sent to your mailbox, please check it carefully',
  'login.trial.alarm.email':
    'The verification code has been sent to your mailbox, please check it carefully',
  'login.trail.alarm.email.not.receive': 'Not received?',
  'login.trial.alarm.email.spam':
    'The mail may blocked by your mail provider with incorrect security policy, please check it in the Spam',
  'login.trial.alarm.reg.mobile':
    'The account information has been sent to your mailbox, please check it carefully',
  'login.trial.alarm.reg.email':
    'The account information has been sent to your mailbox, please check it carefully',
  'login.trial.captcha.mobile.format':
    'The format of mobile phone number is incorrect. Please enter again',
  'login.trial.contacts.email.format':
    'The format of mailbox number is incorrect. Please enter again',
  'login.trial.contacts.mobile.legal': 'Illegal mobile phone format',
  'login.trial.contacts.email.legal': 'Illegal mailbox format',

  'login.app.title': 'CloudPatrol+ App',
  'login.app.subTitle': '「 Pure Patrol Management 」',
  'login.app.desc1': 'Scan the QR code on the left to download the app',
  'login.app.desc2': 'Scan the QR code on the right to log in the app',
  'login.app.desc3': 'Use Username and Password to login',

  'login.slogan': 'Guard Patrol System',
  'login.vaptcha.text': 'Plugin is initializing...',

  'login.nocaptcha.loading': 'Loading...',
  'login.nocaptcha.guide': 'Slide to right to verify',
  'login.nocaptcha.pass': 'Pass',
  'login.nocaptcha.error': 'Oops..., click <a href="javascript:__nc.reset()">here</a> to retry',
  'login.nocaptcha.offline':
    'Network error, click <a href="javascript:__nc.reset()">here</a> to refresh',

  'login.expired.exit.desc': 'Credentials expired, please login again...',

  'login.companyCode.illegal': 'Illegal Company Code',
};
