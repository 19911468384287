export default {
  'content.title': '巡檢內容',
  'content.btn.add': '增加內容',
  'content.btn.site': '選擇地點',
  'content.btn.choice': '添加選項',
  'content.label.choice': '選項內容',
  'content.input.content.placeholder': '請輸入內容標題',
  'content.input.des.placeholder': '請輸入內容描述',
  'content.input.choice.placeholder': '請輸入選項內容',
  'content.notification.description': '該選項內容已存在，不能重復添加',
  'content.notification.choice.blank': '提交方式為單選或者多選時，必須輸入巡檢內容！',
};
