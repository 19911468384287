import request from '@/utils/request';
import { stringify } from 'qs';
import { getRequestPrefix } from '@/utils/profile';

export async function getDeptTree() {
  return request(`/${getRequestPrefix()}/dept/loadDeptTree?Type=Param`);
}

export async function getStatisticOverview() {
  return request(`/${getRequestPrefix()}/statistic_overview`);
}

export async function getDepts(params) {
  return request(`/${getRequestPrefix()}/chart_overview/depts?${stringify(params)}`);
}

export async function getChartBar(params) {
  return request(`/${getRequestPrefix()}/chart_overview/charts?${stringify(params)}`);
}

export async function getChartRank(params) {
  return request(`/${getRequestPrefix()}/chart_overview/ranks?${stringify(params)}`);
}

export async function getLatestAlarms(params) {
  return request(`/${getRequestPrefix()}/latest_overview/alarms?${stringify(params)}`);
}

export async function getLatestLogs(params) {
  return request(`/${getRequestPrefix()}/latest_overview/logs?${stringify(params)}`);
}

export async function getLatestHistories(params) {
  return request(`/${getRequestPrefix()}/latest_overview/histories?${stringify(params)}`);
}

export async function getLatestMedias() {
  return request(`/${getRequestPrefix()}/latest_overview/medias`);
}

export async function getLatestHeatMaps() {
  return request(`/${getRequestPrefix()}/latest_overview/heatmap`);
}

export async function getSystemOverview() {
  return request(`/${getRequestPrefix()}/system_overview`);
}

export async function changePassWord(params) {
  return request(`/api/system/passWord?${stringify(params)}`, {
    method: 'PUT',
  });
}

export async function regvDeviceType(params) {
  return request(`/api/system/registerDevice?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function loadModules(params) {
  return request(`/api/system/loadModules?${stringify(params)}`);
}

export async function delDeviceType(params) {
  return request(`/api/device/delRegisterDevice?${stringify(params)}`, {
    method: 'DELETE',
  });
}

// 巡检宝服务地址
export async function getAppQr(params) {
  return request(`/api/app/qr?${stringify(params)}`, {
    method: 'GET',
    responseType: 'blob',
  });
}

// 云巡检+服务地址
export async function getAppQrPlus(params) {
  return request(`/api/app/qrplus?${stringify(params)}`, {
    method: 'GET',
    responseType: 'blob',
  });
}
