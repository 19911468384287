export default {
  'menu.home': 'Maison',
  'menu.dashboard': 'Tableau de bord',

  'menu.basic': 'Opération de base',
  'menu.basic.read': 'Lire les données',
  'menu.basic.history': "Données d'historique",
  'menu.basic.report': 'Rapport de patrouille',
  'menu.basic.detail': 'Report Detail',
  'menu.basic.chart': 'Tableau statistique',
  'menu.basic.alarm': "Données d'alarme",
  'menu.basic.omit': "Données d'omission",
  'menu.basic.raw': "Données d'origine",
  'menu.basic.calendar': 'Calendrier des patrouilles',
  'menu.basic.log': 'Informations sur le journal',
  'menu.basic.realmap': 'Surveillance en temps réel',
  'menu.basic.attendance': 'Données de fréquentation',

  'menu.setup': 'Configuration de la patrouille',
  'menu.setup.dept': "Configuration de l'entreprise",
  'menu.setup.event': "Configuration de l'événement",
  'menu.setup.guard': 'Configuration de la garde',
  'menu.setup.site': 'Configuration du point de contrôle',
  'menu.setup.plan': 'Configuration du forfait',
  'menu.setup.book': 'Configuration des contacts',
  'menu.setup.receipt': 'Configuration du reçu',
  'menu.setup.down': 'Télécharger les données',
  'menu.setup.downparam': 'Télécharger les données',
  'menu.setup.content': 'Configuration du contenu',
  'menu.setup.voice': 'Configuration vocale',
  'menu.setup.talk': 'Configuration du talkie-walkie',
  'menu.setup.duty': 'Téléchargement des données de présence',

  'menu.data': 'Maintenance des données',
  'menu.data.device': 'Liste des périphériques',
  'menu.data.devices': "Configuration des paramètres de l'appareil",
  'menu.data.system': 'Configuration des paramètres système',
  'menu.data.role': 'Configuration des autorisations',
  'menu.data.user': "Configuration de l'opérateur",
};
