export default {
  'menu.home': 'Главная',
  'menu.dashboard': 'Панель управления',

  'menu.basic': 'Основные операции',
  'menu.basic.read': 'Прочитать данные',
  'menu.basic.history': 'Журнал данных',
  'menu.basic.report': 'Отчет обходов',
  'menu.basic.detail': 'Детали осмотра',
  'menu.basic.chart': 'Статистика',
  'menu.basic.alarm': 'Тревоги',
  'menu.basic.omit': 'Нарушения обходов',
  'menu.basic.raw': 'Исходные данные',
  'menu.basic.calendar': 'Календарь обходов',
  'menu.basic.log': 'Сис. журнал',
  'menu.basic.realmap': 'Онлайн мониторинг',
  'menu.basic.attendance': 'Данные о прибытиях',

  'menu.setup': 'Настройка обходов',
  'menu.setup.dept': 'Настройка объектов',
  'menu.setup.event': 'Настройка событий',
  'menu.setup.guard': 'Настройка сотрудников',
  'menu.setup.site': 'Настройка меток',
  'menu.setup.plan': 'Настройка графиков',
  'menu.setup.book': 'Настройка тел. контактов',
  'menu.setup.receipt': 'Настройка платежной информации',
  'menu.setup.down': 'Загрузка данных',
  'menu.setup.downparam': 'Загрузка данных',
  'menu.setup.content': 'Чек листы',
  'menu.setup.voice': 'Голосовые настройки',
  'menu.setup.talk': 'Настройка рации',
  'menu.setup.duty': 'Загрузка данных',

  'menu.data': 'Системные настройки',
  'menu.data.device': 'Список устройств',
  'menu.data.system': 'Системные настройки',
  'menu.data.devices': 'Настройки устройства',
  'menu.data.params': 'Настройка параметров',
  'menu.data.role': 'Настройка ролей',
  'menu.data.user': 'Смена пароля',
};
