import menu from './zh-CN/menu';
import pwa from './zh-CN/pwa';
import exception from './zh-CN/exception';
import request from './zh-CN/request';
import login from './zh-CN/login';
import dashboard from './zh-CN/dashboard';
import header from './zh-CN/header';
import general from './zh-CN/general';
import map from './zh-CN/map';
import device from './zh-CN/device';
import system from './zh-CN/system';
import sound from './zh-CN/sound';
import communication from './zh-CN/communication';
import alarm from './zh-CN/alarm';
import dept from './zh-CN/dept';
import event from './zh-CN/event';
import guard from './zh-CN/guard';
import site from './zh-CN/site';
import plan from './zh-CN/plan';
import role from './zh-CN/role';
import user from './zh-CN/user';
import talk from './zh-CN/talk';
import content from './zh-CN/content';
import book from './zh-CN/book';
import receipt from './zh-CN/receipt';
import query from './zh-CN/query';
import common from './zh-CN/common';
import bi from './zh-CN/bi';
import company from './zh-CN/company';
import backup from './zh-CN/backup';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,
  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...plan,
  ...content,
  ...common,
  ...user,
  ...role,
  ...book,
  ...receipt,
  ...query,
  ...talk,
  ...backup,

  ...bi,
  ...company,

  app: '巡检平台系统',
  producer: '沈阳金万码高科技发展有限公司',
  oldaddress: '老版本地址',
  sys_notification:
    '由于云平台底层硬件服务升级可能对系统造成的影响，本系统将于4月3日凌晨进行维护升级，升级时间预计1小时，系统升级期间，数据将无法上传。',
};
