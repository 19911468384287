export default {
  'event.title': '巡檢事件',
  'event.btn.add': '增加事件',
  'event.btn.import': '導入事件',
  'event.btn.export': '導出事件',
  'event.table.code': '事件卡號',
  'event.table.name': '事件名稱',
  'event.table.simple': '事件卡',
  'event.table.common': '普通事件',
  'event.input.code.placeholder': '請輸入事件卡號',
  'event.input.name.placeholder': '請輸入事件名稱',
};
