export default {
  'menu.home': '首頁',
  'menu.dashboard': '工作台',

  'menu.basic': '基礎操作',
  'menu.basic.read': '讀取數據',
  'menu.basic.history': '曆史數據',
  'menu.basic.report': '巡檢報告',
  'menu.basic.detail': '巡檢明細',
  'menu.basic.chart': '統計圖表',
  'menu.basic.alarm': '報警數據',
  'menu.basic.omit': '漏檢數據',
  'menu.basic.raw': '原始數據',
  'menu.basic.calendar': '日曆展示',
  'menu.basic.log': '日志數據',
  'menu.basic.realmap': '實時監控',
  'menu.basic.attendance': '考勤數據',

  'menu.setup': '巡檢設置',
  'menu.setup.dept': '區域設置',
  'menu.setup.event': '事件設置',
  'menu.setup.guard': '人員設置',
  'menu.setup.site': '地點設置',
  'menu.setup.plan': '計劃設置',
  'menu.setup.book': '通訊錄設置',
  'menu.setup.receipt': '回執信息設置',
  'menu.setup.down': '下載數據',
  'menu.setup.downparam': '下載數據',
  'menu.setup.content': '巡檢內容',
  'menu.setup.voice': '巡檢語音',
  'menu.setup.talk': '對講設置',
  'menu.setup.duty': '考勤數據下載',

  'menu.data': '數據維護',
  'menu.data.device': '巡檢設備列表',
  'menu.data.system': '系統參數設置',
  'menu.data.devices': '設備參數設置',
  'menu.data.params': '參數設置',
  'menu.data.role': '系統權限設置',
  'menu.data.user': '操作員管理',
};
