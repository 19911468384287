export default {
  'book.title': '通訊錄',
  'book.btn.add': '增加通訊錄',
  'book.btn.import': '導入通訊錄',
  'book.btn.export': '導出通訊錄',
  'book.table.name': '聯繫人姓名',
  'book.table.tel': '聯繫人電話',
  'book.input.name': '請輸入聯繫人姓名',
  'book.input.tel': '請輸入聯繫人電話',
};
