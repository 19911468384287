export default {
  'menu.data.backupPlan': '备份计划',
  'menu.data.backupFile': '文件列表',
  'backup.plan.input.placeholder': '请输入', // 多的时候可以用，加这个只是为了少定义几个组件相关的placeholder（当然了也适用于rule），如果表单组件少那就不需要了。
  'backup.input.styleErr': '格式错误',

  // 计划部分
  'backup.plan.title': '备份计划',
  'backup.plan.add': '增加备份计划',
  'backup.plan.edit': '编辑',
  'backup.plan.del': '删除备份计划',

  'backup.plan.table.name': '计划名称',
  'backup.plan.table.period': '周期类型',
  'backup.plan.table.periodValue': '周期数',
  'backup.plan.table.backupTime': '备份时间',
  'backup.plan.table.backupPath': '备份路径',
  'backup.plan.table.reserveDay': '文件保留天数',
  'backup.table.operate': '操作',

  'backup.plan.input.backupPath.remark': '需提前创建，不输入则使用默认位置',

  'backup.plan.period.day': '天',
  'backup.plan.period.week': '周',
  'backup.plan.period.month': '月',

  // 文件部分
  'backup.file.manual': '手动备份',
  'backup.file.upload': '导入备份',
  'backup.file.del': '删除备份',
  'backup.file.download': '下载',
  'backup.file.restore': '恢复',

  'backup.file.table.fileName': '文件名称',
  'backup.file.table.datasource': '数据来源',
  'backup.file.table.createTime': '备份时间',
  'backup.file.table.fileSize': '文件大小',
  'backup.file.manual.filePath': '文件存储路径',
  'backup.file.table.isSuccess': '操作结果',

  'backup.file.isSuccess.ok': '成功',
  'backup.file.isSuccess.fail': '失败',

  'backup.file.restore.warnInfo':
    '重要提示：使用该文件恢复后，系统将清除所有数据并还原到该文件对应时期，请谨慎斟酌是否恢复？',
  'backup.file.restore.success.msg': '恢复{back}成功，请重新登录系统',
  'backup.file.restore.auth': '恢复操作验证',
  'backup.file.restore.authErr': '恢复操作验证失败',
};
