export default {
  'bi.title': '巡檢數據可視化大屏',
  'bi.guard': '人員',
  'bi.site': '地點',
  'bi.source': '來源',
  'bi.plan': '計劃',
  'bi.device': '設備',
  'bi.record': '記錄',

  'bi.time': '時間',
  'bi.no': '序號',

  'bi.record.trend': '數據流量趨勢',
  'bi.record.average': '平均流量',
  'bi.record.upload': '上傳流量',
  'bi.record.unit': '條',

  'bi.record.list': '數據流量明細',
  'bi.record.': '數據流量明細',

  'bi.qualified': '合格',
  'bi.unqualified': '漏巡',
  'bi.todo': '待巡',

  'bi.qualified.rate': '合格率',
  'bi.unqualified.rate': '漏巡率',

  'bi.rate.today': '今日合格率統計',
  'bi.attendacne.rank': '出勤率排行榜',
  'bi.rate.trend': '合格率趨勢',

  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  sunday: '星期日',

  'bi.empty': '暫無數據',

  'bi.largest.guide': '請手動調整瀏覽器窗口至最大化以達到更好的大屏顯示效果',
  'bi.largest.ignore': '直接進入',
};
