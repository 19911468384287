export default {
  'exception.fail': 'Demande échoué',
  'exception.unknow': 'Une erreur inconnue se produit',
  'exception.network': 'Erreur réseau',
  'exception.network.desc': 'Impossible de se connecter au serveur',

  'exception.403': "Désolé, vous n'êtes pas autorisé à accéder à cette ressource.",
  'exception.404': 'Oups..., la ressource est introuvable.',
  'exception.500': 'Orz, le serveur a peut-être rencontré quelques soucis.',

  'exception.400': 'Mauvaise demande',
  'exception.401': 'Identifiants utilisateur non valides',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': "Délai d'expiration de la passerelle",

  'exception.crash': "Oups, il semble que nous ayons rencontré quelque chose d'inattendu...",
};
