export default {
  'device.title': '參數設定',
  'device.system': '繫統設定',
  'device.device': '設備設定',
  'device.modal.title': '提示',
  'device.modal.ok': '確定',
  'device.modal.edit': '編輯',
  'device.modal.save': '保存',
  'device.modal.del': '刪除',
  'device.modal.cancel': '取消',
  'device.modal.operate': '操作',
  'device.modal.setsuccess': '設定成功',
  'device.modal.seterr': '設定失敗',
  'device.modal.delconfirm': '確定要刪除選擇的數據嗎？',
  'device.modal.delsuccess': '數據刪除成功！',
  'device.modal.delerr': '數據刪除失敗！',
  'device.system.basic': '基本參數',
  'device.system.basic.event': '事件功能',
  'device.system.basic.cycle': '按次計劃',
  'device.system.basic.cost': '巡檢用時',
  'device.system.basic.cost.des': '是否在巡檢報告中顯示計劃的每圈巡檢用時',
  'device.system.basic.stay': '停留時間',
  'device.system.basic.stay.des': '是否在巡檢報告中顯示每個點的停留時間',
  'device.system.basic.order': '順序巡檢',
  'device.system.basic.date': '日期格式',
  'device.system.basic.enable': '啟用',
  'device.system.basic.disenable': '禁用',
  'device.device.btn.parameter': '設備參數設定',
  'device.device.btn.parameter.remote': '遠程參數設定',
  'device.device.btn.delalldevicedata': '確定要清除設備中的全部數據嗎？',
  'device.device.btn.delalldeviceclock': '確定要清除設備中的鬧鈴嗎？',
  'device.device.btn.deldevicedata': '清除設備數據',
  'device.device.btn.conn': '通訊參數設定',
  'device.device.btn.delfinger': '清除設備指紋',
  'device.device.btn.init': '設備初始化',
  'device.device.btn.initconfirm': '確定要將設備恢復到出廠設定狀態嗎？',
  'device.device.MsgNoReader': '請先插入巡檢設備，再進行通信',
  'device.device.MsgNoFount': '未找到巡檢設備，請確認是否已連接',
  'device.device.MsgWriteFailed': '寫入數據失敗',
  'device.device.MsgOpenFailed': '打開設備失敗',
  'device.device.MsgReadFailed': '讀取設備數據失敗.',
  'device.device.MsgConnFailed': '設備連接失敗.',
  'device.device.MsgTypeFailed': '設備類別錯誤',
  'device.device.noparam': '當前接入設備不需要進行參數設定',
  'device.device.init': '確定要將設備恢復到出廠設定嗎？',
  'device.system.init': '繫統初始化',

  'device.btn.download.all': '下載全部數據',
  'device.btn.download.select': '下載選中數據',

  'device.guardname': '人員顯示名稱',
  'device.guardname.place': '請輸入人員名稱',
  'device.guardname.des': '用於顯示在液晶屏上的名稱',

  'device.clock': '鬧鈴設定',
  'device.clock.sn': '序號',
  'device.clock.name': '鬧鈴時間',
  'device.clock.add': '增加鬧鈴',
  'device.clock.del': '刪除鬧鈴',
  'device.clock.down': '下載鬧鈴',
  'device.clock.delDown': '刪除下載',
  'device.clock.exittime': '輸入的鬧鈴時間已存在，請重新輸入',
  'device.clock.nulltime': '請輸入鬧鈴時間',
  'device.clock.max': '鬧鈴最多只能下載200組，請重新選擇',

  'device.flashlight.closemode': '手電關閉方式',
  'device.flashlight.closemode.dafault': '預設',
  'device.flashlight.closemode.20': '點亮20秒自動關閉',
  'device.flashlight.closemode.auto': '自動關閉',
  'device.flashlight.closemode.manual': '手動關閉',
  'device.flashlight.closemode.closetime': '手電關閉時間',

  'device.alarm.title': '報警按鍵功能',
  'device.alarm.choice1': '按住此鍵1秒後發送報警信息',
  'device.alarm.choice2': '按住此鍵2秒後發送報警信息',
  'device.alarm.choice3': '按住此鍵3秒後發送報警信息',
  'device.alarm.choice4': '按住此鍵4秒後發送報警信息',
  'device.alarm.choice5': '按住此鍵5秒後發送報警信息',
  'device.alarm.choice6': '按住此鍵6秒後發送報警信息',
  'device.alarm.choice7': '按住此鍵7秒後發送報警信息',
  'device.alarm.choice8': '按住此鍵8秒後發送報警信息',
  'device.alarm.choice9': '按住此鍵9秒後發送報警信息',
  'device.alarm.choice10': '按住此鍵10秒後發送報警信息',
  'device.alarm.choice11': '按住此鍵 {count} 秒後髮送報警信息',
  'device.shuttype': '關機方式',
  'device.shuttype.auto': '自動關機',
  'device.shuttype.manual': '手動關機',
  'device.shuttype.time': '關機時間',
  'device.shuttype.timeandunit': '關機時間（分鐘）',
  'device.shuttype.timeandunits': '關機時間（秒）',
  'device.shuttype.autotime': '自動關閉時間',
  'device.shuttype.unit': '單位',
  'device.shuttype.unit.minute': '分鐘',
  'device.shuttype.unit.seconds': '秒',

  'device.screen.resolution': '分辨率',
  'device.screen.recordtime': '錄像時間（秒）',
  'device.screen.number': '簡訊中心號碼',

  'device.drop.meter1': '一米高度自由落體',
  'device.drop.meter2': '二米高度自由落體',
  'device.drop.meter3': '三米高度自由落體',

  'device.step.state': '計步器狀態',
  'device.impact.state': '摔碰報警狀態',
  'device.impact.incline': '傾斜報警狀態',
  'device.impact.incline.param1': '傾斜報警參數1',
  'device.impact.incline.param2': '傾斜報警參數2',
  'device.impact.incline.param3': '傾斜報警參數3',
  'device.impact.incline.angle': '傾斜報警角度',
  'device.impact.condition': '摔碰報警條件',
  'device.impact.static.param1': '靜止報警參數1',
  'device.impact.static.param2': '靜止報警參數2',
  'device.impact.static.param3': '靜止報警參數3',
  'device.impact.static.sensitivity': '靈敏度',
  'device.static.condition': '靜止報警狀態',

  'device.clock.func': '鬧鈴功能',
  'device.clock.h2.ipmode': '通訊模式',
  'device.clock.h2.dynamicip': '動態IP',
  'device.clock.h2.staticip': '靜態IP',

  'device.clock.h2.ipaddress': 'IP位址',
  'device.clock.h2.subnet': '子網掩碼',
  'device.clock.h2.gateway': '網關',
  'device.clock.h2.wifiname': 'WiFi名稱',
  'device.clock.h2.wifipwd': 'WiFi密碼',
  'device.clock.h2.wififunc': 'WiFi功能',
  'device.clock.nullwifiname': '請輸入WIFI名稱',
  'device.clock.nullwifipwd': '請輸入WIFI密碼',
  'device.clock.h2.interval': '鬧鈴提醒間隔時間（秒）',
  'device.clock.h2.times': '鬧鈴提醒次數',
  'device.clock.h2.intervaltimes': '上載時間間隔（秒）',

  'device.call.type': '來電提醒方式',
  'device.call.type.mute': '靜音',
  'device.call.type.vibration': '震動',
  'device.call.type.ring': '鈴音',
  'device.call.type.both': '震動+鈴音',

  'device.voice.state': '語音狀態',
  'device.voice.volume': '音量大小',
  'device.voice.volume.high': '高',
  'device.voice.volume.middle': '中',
  'device.voice.volume.low': '低',

  'device.finger.state': '指紋狀態',
  'device.finger.verifyone': '有-驗證一次',
  'device.finger.verifycard': '有-讀卡驗證',
  'device.finger.stitching': '指紋拼接次數',
  'device.finger.confirmdel': '確定要刪除設備中的指紋數據嗎，一旦刪除將無法恢復？',
  'device.devicedata.deldatafirst': '刪除指紋之前必須先清除設備中數據，刪除指紋失敗！',
  'device.temperature.display': '溫度顯示方式',
  'device.temperature.celsius': '攝氏度',
  'device.temperature.fahrenheit': '華氏度',

  'device.gps.working': '工作模式',
  'device.gps.patrol': '巡檢模式',
  'device.gps.coll': '採點模式',
  'device.gps.interval': '定位時間間隔（秒）',
  'device.gps.collgps': '採集GPS數據時間（零代錶不採集）',

  'device.conn.title': '通訊參數設定',
  'device.conn.type': '連接方式',
  'device.conn.ip': '通過IP地址進行連接',
  'device.conn.domain': '通過域名地址進行連接',
  'device.conn.domain.name': '域名',
  'device.conn.port': '端口號',
  'device.conn.isp': '營運商',
  'device.conn.mobile': '中國移動',
  'device.conn.unicom': '中國聯通',
  'device.conn.otherisp': '其他營運商',
  'device.conn.user': '登陸用戶',
  'device.conn.pwd': '登陸密碼',
  'device.send.mode': '數據上載方式',
  'device.send.auto': '自動發送數據',
  'device.send.manu': '手動發送數據',
  'device.send.func': '發送按鍵功能',
  'device.send.func1': '按住此鍵1秒後發送數據信息',
  'device.send.func2': '按住此鍵2秒後發送數據信息',
  'device.send.func3': '按住此鍵3秒後發送數據信息',
  'device.send.func4': '按住此鍵4秒後發送數據信息',
  'device.send.func5': '按住此鍵5秒後發送數據信息',
  'device.send.func6': '按住此鍵6秒後發送數據信息',
  'device.send.func7': '按住此鍵7秒後發送數據信息',
  'device.send.func8': '按住此鍵8秒後發送數據信息',
  'device.send.func9': '按住此鍵9秒後發送數據信息',
  'device.send.func10': '按住此鍵10秒後發送數據信息',
  'device.send.func11': '按住此鍵 {count} 秒後發送數據信息',

  'device.read.data.loading': '數據上載中，請稍等...',
  'device.read.data.blank': '設備內無數據',
  'device.read.data.success': '數據讀取成功，記錄數：',
  'device.timing.success': '校時成功，設備號：',
  'device.timing.devicetype': '設備類型：',
  'device.timing.failed': '校時失敗',
  'device.read.failed': '讀取失敗',
  'device.serverTime.error': '獲取服務器時間異常',

  'device.status.disconnect': '未檢測到設備',
  'device.status.disconnect.desc': '請通過USB連接妳手裏的設備',

  'device.socket.stop': '通訊組件尚未啟動，請確保已啟動，嘗試刷新界面',
  'device.socket.stop.or': '或',
  'device.socket.download': '下載安裝',
  'device.socket.downloading': '瀏覽器正在下載通訊組件...完成後點選進行手動安裝即可 :)',

  'device.socket.exist': '通訊組件已啟動',
  'device.socket.exist.desc': '通訊組件已下載併啟動，是否仍然繼續？',

  'device.socket.busy': '通訊繁忙，請稍後再試...',
  'device.socket.timeout': '通訊超時...',

  'device.register.list': '已註冊設備類型',
  'device.register.list.delete': '是否要移除該已註冊設備類型？',
  'device.unregister.title': '未註冊的設備類型',
  'device.unregister.content': '檢測到未註冊的設備類型{type}，是否立即註冊？',

  'device.register.success': '設備類型註冊成功！',

  'device.btn.add': '添加設備',
  'device.btn.active': '激活設備',
  'device.btn.replace': '替換設備',
  'device.btn.remark': '修改備註',
  'device.btn.param': '遠程參數設定',
  'device.btn.home': '返回首頁',

  'device.table.device.code': '設備號碼',
  'device.table.device.code.des': '請輸入設備號碼',
  'device.table.device.type': '設備類型',
  'device.table.device.time': '註冊時間',
  'device.table.device.account': '賬號',
  'device.table.device.name': '姓名',
  'device.table.device.extra': '備註',
  'device.table.device.range': '起止時間',

  'device.table.card.purchase': '購買數量',
  'device.table.card.Remain': '剩余數量',
  'device.table.card.time': '使用期限',
  'device.table.card.purchasetime': '購買時間',
  'device.table.card.year': ' 年',
  'device.table.card.month': ' 個月',
  'device.table.card.date': ' 天',

  'device.modal.remark.title': '備註信息',
  'device.modal.remark.placeholder': '請輸入備註信息',
  'device.modal.remark.tip': '如果輸入區域名稱，則該區域下的操作員都可以查看此設備信息',

  'device.notification.description.add': '設備添加成功',
  'device.notification.description.noneed': '當前選擇的設備不需要進行激活操作',
  'device.notification.description.noend':
    '當前選擇的設備不需要進行激活操作，請在設備有效期截止前一個月再進行激活設備操作',
  'device.notification.description.type.wrong':
    '選擇的設備與當前激活碼對應的設備類型不一致，不能完成激活設備操作',
  'device.notification.description.success': '設備激活成功',
  'devidevice.notification.description.typece.notification.description.failed': '設備激活失敗',
  'device.notification.description.type':
    '選擇的設備與當前接入設備類型不一致，不能完成替換設備操作，請選擇相同類型的設備進行替換操作',
  'device.notification.description.code':
    '當前接入的設備與選擇的設備號碼相同，不能完成替換設備操作，請選擇設備號碼不同的設備進行替換操作',
  'device.notification.description.confirm':
    '確定要用新設備對原設備進行替換操作嗎？替換之後，原設備只能讀取替換時間之前的數據，新設備的有效數據將從替換時間之後開始',
  'device.notification.description.unsupport': '當前接入設備不支援此功能',
  'device.notification.description.nosite':
    '不能下載沒有巡檢地點的計劃信息，請先進行巡檢地點的設定，然後再下載數據',
  'device.notification.description.noguard':
    '沒有可供下載的巡檢人員，請先進行巡檢人員的設定，然後再下載數據',
  'device.notification.description.noplan':
    '沒有可供下載的巡檢計劃，請先進行巡檢計劃的設定，然後再下載數據',
  'device.notification.description.clearbook':
    '當前尚未選擇任何記錄進行下載，如果未選擇記錄進行下載，將會清除設備中的通訊錄信息，是否繼續此項操作？',
  'device.notification.description.unselect': '請先勾選要操作的設備信息',
};
