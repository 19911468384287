export default {
  'system.title': 'Настройки системных параметров',
  'system.event': 'Функция события',
  'system.event.des': 'Поддерживается ли функция события',
  'system.plan': 'повременный план',
  'system.plan.des':
    'Планы, которые могут выполняться периодически, например, ежедневное, еженедельное, ежемесячное выполнение',
  'system.patroltime': 'Время патрулирования',
  'system.patroltime.des':
    'Отображать ли общее запланированное время осмотра за круг в отчете об осмотре',
  'system.remaintime': 'Оставшееся время',
  'system.remaintime.des':
    'Отображать ли общее запланированное время проверки на круг в отчете об инспекции',
  'system.Sequence': 'Проверка последовательности',
  'system.Sequence.des':
    'Указывает ли он, что каждая точка проверки в плане должна быть проверена в порядке, установленном в плане',
  'system.timeformat': 'Формат даты',
  'system.timeformate.des': 'Формат даты, отображаемый системой',
  'system.dst': 'Летнее время',
  'system.dst.des':
    'Независимо от того, включено ли летнее время, после изменения соответствующее время запланированных данных вступит в силу со следующего дня',
  'system.database.period': 'цикл резервного копирования баз данных',
  'system.database.period.des': 'частота резервного копирования базы данных: день',
  'system.database.time': 'время резервного копирования базы данных',
  'system.database.time.des': 'Попробуйте выбрать время с низкой пиковой отдачей данных',
  'system.database.path': 'путь к Резервному копированию баз данных',
  'system.database.path.des':
    'Введите путь к Резервному копированию базы данных, например: d:\backup. если путь пуст, база данных не будет резервной',
  'system.save': 'Сохранить настройки',
  'system.enable': 'Включить',
  'system.disable': 'Отключить',
  'system.notification.prompt': 'Подсказка',
  'system.notification.success': 'Сохранить успешно! ',
};
