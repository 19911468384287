import { getPlans, getCheckpoints, getTracks, closeAlarm } from '@/services/map';

const MapModel = {
  namespace: 'map',
  state: {
    realData: [],
    trackData: [],
    reciveData: [],
  },
  effects: {
    // TODO: 这里错误回调有问题，返回了空数组，会导致异常无法被捕获
    *fetchPlans({ payload, callback }, { call }) {
      try {
        const response = yield call(getPlans, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *fetchCheckpoints({ payload, callback }, { call }) {
      try {
        const response = yield call(getCheckpoints, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *fetchTracks({ payload, callback }, { call }) {
      try {
        const response = yield call(getTracks, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
    *closeAlarm({ payload, callback }, { call }) {
      try {
        const response = yield call(closeAlarm, payload);
        if (callback && typeof callback === 'function') callback(response);
      } catch (e) {
        if (callback && typeof callback === 'function') callback([]);
      }
    },
  },
  reducers: {
    changereciveData(state, payload) {
      return {
        ...state,
        realData: payload.payload.realData,
      };
    },
  },
};
export default MapModel;
