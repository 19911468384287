export default {
  'device.title': 'Ρυθμίσεις παραμέτρων',
  'device.system': 'Ρυθμίσεις Συστήματος',
  'device.device': 'Ρυθμίσεις Συσκευής',
  'device.modal.title': 'Προτροπή',
  'device.modal.ok': 'OK',
  'device.modal.edit': 'Επεξεργασία',
  'device.modal.save': 'Αποθήκευση',
  'device.modal.del': 'Διαγραφή',
  'device.modal.cancel': 'Ακύρωση',
  'device.modal.operate': 'Λειτουργία',
  'device.modal.setsuccess': 'Επιτυχής Ρύθμιση',
  'device.modal.seterr': 'Αποτυχία Ρύθμισης',
  'device.modal.delconfirm': 'Θέλετε να διαγράψετε τα επιλεγμένα δεδομένα;',
  'device.modal.delsuccess': 'Επιτυχής διαγραφή',
  'device.modal.delerr': 'Αποτυχία διαγραφής',
  'device.system.basic': 'Βασικές παράμετροι',
  'device.system.basic.event': 'Συμβάν εξόδου',
  'device.system.basic.cycle': 'Ειδικό Πλάνο',
  'device.system.basic.cost': 'Χρόνος Περιπολίας',
  'device.system.basic.cost.des': 'Αν θα εμφανιστεί η ώρα περιπολίας στην αναφορά',
  'device.system.basic.stay': 'Χρόνος που απομένει',
  'device.system.basic.stay.des': 'Αν θα εμφανιστεί ο χρόνος που απομένει στην αναφορά',
  'device.system.basic.order': 'Ακολουθία Περιπολίας',
  'device.system.basic.date': 'Μορφή ημερομηνίας',
  'device.system.basic.enable': 'Ενεργοποίηση',
  'device.system.basic.disenable': 'Απενεργοποίηση',
  'device.device.btn.parameter': 'Ρυθμίσεις συσκευής',
  'device.device.btn.parameter.remote': 'Ρυθμίσεις παραμέτρων ασπομακρυσμένης συσκευής',
  'device.device.btn.delalldevicedata': 'Θέλετε να γίνει διαγραφή δεδομένων στη συσκευή;',
  'device.device.btn.delalldeviceclock': 'Επιβεβαίωση διαγραφής υπενθυμίσεων στη συσκευή',
  'device.device.btn.deldevicedata': 'Καθαρισμός δεδομένων συσκευής',
  'device.device.btn.conn': 'Ρυθμίσεις επικοινωνίας',
  'device.device.btn.delfinger': 'Αφαίρεση δακτ.αποτυπωμάτων',
  'device.device.btn.init': 'Αρχικοποίηση συσκευής',
  'device.device.btn.initconfirm':
    'Είστε βέβαιοι ότι θέλετε να επαναφέρετε τη συσκευή σας στις εργοστασιακές ρυθμίσεις;',
  'device.device.MsgNoReader': 'Παρακαλώ συνδέστε τη συσκευή',
  'device.device.MsgNoFount': 'Δεν βρέθηκε συσκευή παρακαλώ επιβεβαιώστε',
  'device.device.MsgWriteFailed': 'Αποτυχία εγγραφής',
  'device.device.MsgOpenFailed': 'Άνοιγμα συσκευής απέτυχε',
  'device.device.MsgReadFailed': 'Ανάγνωση δεδομένων απέτυχε.',
  'device.device.MsgConnFailed': 'Σύνδεση συσκευής απέτυχε.',
  'device.device.MsgTypeFailed': 'Λάθος τύπος συσκευής',
  'device.device.noparam': 'Δεν μπορύν να ρυθμιστούν οι παράμετροι της τρέχουσας συσκευής',
  'device.device.init': 'Θέλετε να επαναφέρετε εργοστασιακές ρυθμίσεις στη συσκευή σας;',
  'device.system.init': 'Αρχικοποίηση συστήματος',

  'device.btn.download.all': 'Λήψη όλων των δεοδμένων',
  'device.btn.download.select': 'Λήψη επιλεγμένων δεδομένων',

  'device.guardname': 'Όνομα Φύλακα',
  'device.guardname.place': 'Παρακαλώ εισάγετε όνομα',
  'device.guardname.des': 'Εμφάνιση στην οθόνη',

  'device.clock': 'Ρύθμιση ρολογιού',
  'device.clock.sn': 'NO',
  'device.clock.name': 'Ώρα Ρολογιού',
  'device.clock.add': 'Προσθήκη ρολογιού',
  'device.clock.del': 'Διαγραφή ρολογιού',
  'device.clock.down': 'Λήψη',
  'device.clock.delDown': 'Καθαρισμός Λήψης',
  'device.clock.exittime': 'Η ώρα υπενθύμισης που εισάγατε υπάρχει ήδη',
  'device.clock.nulltime': 'Παρακαλώ επιλέξτε ώρα ρολογιού',
  'device.clock.max':
    'Μπορούν να γίνουν λήψη έως 200 ομάδες συναγερμού κατά το μέγιστο. Παρακαλώ δοκιμάστε πάλι',

  'device.flashlight.closemode': 'Λειτουργία σβησίματος φακού',
  'device.flashlight.closemode.dafault': 'Εργοστασιακό',
  'device.flashlight.closemode.20': 'Σβήσιμο φακού αυτόματα μετά από 20 δευτερόλεπτα.',
  'device.flashlight.closemode.auto': 'Αυτόματο σβήσιμο',
  'device.flashlight.closemode.manual': 'Χειροκίνητο σβήσιμο',
  'device.flashlight.closemode.closetime': 'Χρόνος κλεισίματος',

  'device.alarm.title': 'Πλήκτρο συναγερμού',
  'device.alarm.choice1': 'Πιέστε το πλήκτρο για 1 δευτερόλεπτο για αποστολή συναγερμού',
  'device.alarm.choice2': 'Πιέστε το πλήκτρο για 2 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice3': 'Πιέστε το πλήκτρο για 3 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice4': 'Πιέστε το πλήκτρο για 4 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice5': 'Πιέστε το πλήκτρο για 5 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice6': 'Πιέστε το πλήκτρο για 6 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice7': 'Πιέστε το πλήκτρο για 7 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice8': 'Πιέστε το πλήκτρο για 8 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice9': 'Πιέστε το πλήκτρο για 9 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice10': 'Πιέστε το πλήκτρο για 10 δευτερόλεπτα για αποστολή συναγερμού',
  'device.alarm.choice11': 'Πιέστε το πλήκτρο για {count} δευτερόλεπτα για αποστολή συναγερμού',
  'device.shuttype': 'Λειτουργία Σβησίματος',
  'device.shuttype.auto': 'Αυτόματο κλείσιμο',
  'device.shuttype.manual': 'Χειροκίνητο κλείσιμο',
  'device.shuttype.time': 'Χρόνος Σβησίματος',
  'device.shuttype.timeandunit': 'Χρόνος σβησίματος(λεπτά)',
  'device.shuttype.timeandunits': 'Χρόνος σβησίματος (δευτερόλεπτα)',
  'device.shuttype.autotime': 'Χρόνος κλεισίματος',
  'device.shuttype.unit': 'Μονάδα',
  'device.shuttype.unit.minute': 'Λεπτά',
  'device.shuttype.unit.seconds': 'Δευτερόλεπτα',

  'device.screen.resolution': 'Ανάλυση',
  'device.screen.recordtime': 'Χρόνος Εγγραφής (δ)',
  'device.screen.number': 'Αριθμός κέντρου SMS',

  'device.drop.meter1': 'Πτώση από ένα μέτρο',
  'device.drop.meter2': 'Πτώση από δύο μέτρα',
  'device.drop.meter3': 'Πτώση από τρία μέτρα',

  'device.step.state': 'Κατάσταση Πεδόμετρου',
  'device.impact.state': 'Κατάσταση συναγερμού κρούσης',
  'device.impact.incline': 'Κατάσταση συναγερμού κλίσης',
  'device.impact.incline.param1': 'Παράμετροι συναγερμού κλίσης 1',
  'device.impact.incline.param2': 'Παράμετροι συναγερμού κλίσης 2',
  'device.impact.incline.param3': 'Παράμετροι συναγερμού κλίσης 3',
  'device.impact.incline.angle': 'Γωνία',
  'device.impact.condition': 'Κατάσταση συναγερμού κρούσης',
  'device.impact.static.param1': 'Παράμετρος συναγερμού ακινησίας 1',
  'device.impact.static.param2': 'Παράμετρος συναγερμού ακινησίας 2',
  'device.impact.static.param3': 'Παράμετρος συναγερμού ακινησίας 3',
  'device.impact.static.sensitivity': 'Ευαισθησία',
  'device.static.condition': 'Κατάσταση συναγερμού ακινησίας',

  'device.clock.func': 'Λειτουργίες ρολογιού',
  'device.clock.h2.ipmode': 'Λειτουργία Επινοινωνίας',
  'device.clock.h2.dynamicip': 'Δυναμική IP',
  'device.clock.h2.staticip': 'Στατική IP',
  'device.clock.h2.ipaddress': 'IP διεύθυνση',
  'device.clock.h2.subnet': 'Μάσκα υποδικτύου',
  'device.clock.h2.gateway': 'Προεπ.Πύλη',
  'device.clock.h2.wifiname': 'WiFi όνομα',
  'device.clock.h2.wifipwd': 'WiFi κωδικός',
  'device.clock.h2.wififunc': 'WiFi Λειτουργία',
  'device.clock.nullwifiname': 'Παρακαλώ εισάγετε όνομα Wifi',
  'device.clock.nullwifipwd': 'Παρακαλώ εισάγετε κωδικό Wifi',
  'device.clock.h2.interval': 'Διάστημα συναγερμών υπενθύμισης(δευτερόλεπτα)',
  'device.clock.h2.times': 'Αριθμός συναγερμών υπενθύμισης',
  'device.clock.h2.intervaltimes': 'Χρονικό διάστημα(δευτερόλεπτα)',

  'device.call.type': 'Προτροπές κλήσεων',
  'device.call.type.mute': 'Σίγαση',
  'device.call.type.vibration': 'Δόνηση',
  'device.call.type.ring': 'Κωδωνισμός',
  'device.call.type.both': 'Δόνηση και κωδωνισμός',

  'device.voice.state': 'Λειτουργίες φωνής',
  'device.voice.volume': 'Ένταση',
  'device.voice.volume.high': 'Υψηλό',
  'device.voice.volume.middle': 'Μεσαίο',
  'device.voice.volume.low': 'Χαμηλό',

  'device.finger.state': 'Λειτουργία δακτ.αποτυπώματος',
  'device.finger.verifyone': 'Επαλήθευση μία φορά',
  'device.finger.verifycard': 'επαλήθευση κατά την ανάγνωση σημείου ελέγχου',
  'device.finger.stitching': 'Χρόνοι συρραφής δακτυλικών αποτυπωμάτων',
  'device.finger.confirmdel': 'Θέλετε να διαγράψετε τα δεδομένα δακτυλικών αποτυπωμάτων;',
  'device.devicedata.deldatafirst':
    'Η αφαίρεση δακτυλικού αποτυπώματος απέτυχε, προτού αφαιρέσετε το δακτυλικό αποτύπωμα, διαγράψτε τα δεδομένα στη συσκευή!',
  'device.devicedata.confirmdel': 'Θέλετε διαγραφή δεδομένων στη συσκευή;',
  'device.temperature.display': 'Λειτουργία θερμοκρασίας',
  'device.temperature.celsius': 'Κελσίου',
  'device.temperature.fahrenheit': 'Φαρενάϊτ',

  'device.gps.working': 'Τύπος Λειτουργίας',
  'device.gps.patrol': 'Λειτουργία Περιπολίας',
  'device.gps.coll': 'Λειτουργία συλλογής σημείων ελέγχου',
  'device.gps.interval': 'Ώρα(δ)',
  'device.gps.collgps': 'ώρα για συλλογή θέσης GPS',

  'device.conn.title': 'Ρυθμίσεις επικοινωνίας',
  'device.conn.type': 'Σύνδεση',
  'device.conn.ip': 'Σύνδεση με διεύθυνση IP',
  'device.conn.domain': 'Σύνδεση',
  'device.conn.domain.name': 'Τομέας',
  'device.conn.port': 'Θύρα',
  'device.conn.isp': 'ISP',
  'device.conn.mobile': 'China Mobile',
  'device.conn.unicom': 'China Unicom',
  'device.conn.otherisp': 'Άλλος ISP',
  'device.conn.user': 'Χρήστης',
  'device.conn.pwd': 'Κωδικός',
  'device.send.mode': 'Λειτουργία μεταφοράς',
  'device.send.auto': 'Αποστολή δεδομένων αυτόματα',
  'device.send.manu': 'Αποστολή δεδομένων χειροκίνητα',
  'device.send.func': '	Πιέστε πλήκτρο',
  'device.send.func1': 'Πιέστε το πλήκτρο για 1 δευτερόλεπτο για αποστολή δεδομένων',
  'device.send.func2': 'Πιέστε το πλήκτρο για 2 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func3': 'Πιέστε το πλήκτρο για 3 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func4': 'Πιέστε το πλήκτρο για 4 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func5': 'Πιέστε το πλήκτρο για 5 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func6': 'Πιέστε το πλήκτρο για 6 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func7': 'Πιέστε το πλήκτρο για 7 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func8': 'Πιέστε το πλήκτρο για 8 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func9': 'Πιέστε το πλήκτρο για 9 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func10': 'Πιέστε το πλήκτρο για 10 δευτερόλεπτα για αποστολή δεδομένων',
  'device.send.func11': 'Πιέστε το πλήκτρο για {count} δευτερόλεπτα για να αποσταλλούν δεδομένα',

  'device.read.data.loading': 'Φορτώνει...',
  'device.read.data.blank': 'όχι δεδομένα στη συσκευή.',
  'device.read.data.success': 'Επιτυχής ανάγνωση. Οι συνολικές εγγραφές είναι: ',
  'device.timing.success': 'Επιτυχής χρονιμσός. Κωδικός Συσκευής: ',
  'device.timing.failed': 'Αποτυχία χρονισμού',
  'device.read.failed': 'Αποτυχία ανάγνωσης',
  'device.serverTime.error': 'Αποτυχία λήψης ώρας server',
  'device.timing.devicetype': 'Τύπος συσκευής: ',

  'device.status.disconnect': 'Η συσκευή δεν βρέθηκε',
  'device.status.disconnect.desc': 'Παρακαλώ συνδέστε τη συσκευή σδας με USB',

  'device.socket.stop': 'Ο οδηγός επικοινωνίας δε λειτουργεί. Παρακαλώ εκτελέστε τον',
  'device.socket.stop.or': ' ή ',
  'device.socket.download': 'κάνετε λήψη',
  'device.socket.downloading':
    'Γίνεται λήψη του οδηγού από το φυλλομετρητή τώρα, μπορείτε να το εγκαταστήσετε όταν ολοκληρωθεί η λήψη',

  'device.socket.exist': 'Ο οδηγός εκτελείται',
  'device.socket.exist.desc':
    'Έχει γίνει ήδη λήψη του οδηγού και εκτελείται. Θέλετε να γίνει πάλι λήψη;',

  'device.socket.busy': 'Ο οδηγός είναι απασχολημένος. Παρακαλώ δοκιμάστε πάλι...',
  'device.socket.timeout': 'Λήξη χρονικού ορίου οδηγού...',

  'device.register.list': 'Τύποι καταχωρημένων συσκευών',
  'device.register.list.delete':
    'Σίγουρα θέλετε να διαγράψετε αυτόν τον καταχωρημένο τύπο συσκευής;',
  'device.unregister.title': 'Μη καταχωρημένος τύπος συσκευής',
  'device.unregister.content':
    'Μη καταχωρημένος τύπος συσκευής {type}, Θέλετε να την καταχωρήσετε τώρα;',

  'device.register.success': 'Ο τύπος συσκευής καταχωρήθηκε με επιτυχία',

  'device.btn.add': 'Προσθήκη συσκευής',
  'device.btn.active': 'Ενεργοποίηση συσκευής',
  'device.btn.replace': 'Αντικατάσταση συσκευής',
  'device.btn.remark': 'Σχόλιο συσκευής',
  'device.btn.param': 'Ρυθμίσεις απομακρυσμένων παραμέτρων',
  'device.btn.home': 'Επιστροφή στην Αρχική σελίδα',

  'device.table.device.code': 'Κωδικός συσκευής',
  'device.table.device.code.des': 'Παρακαλώ εισάγετε κωδικό συσκευής',
  'device.table.device.type': 'Τύπος συσκευής',
  'device.table.device.time': 'Ώρα Εγγραφής',
  'device.table.device.account': 'Λογαριασμός',
  'device.table.device.name': 'Όνομα',
  'device.table.device.extra': 'Σχόλιο',
  'device.table.device.range': 'ώρα Έναρξης και Λήξης',

  'device.table.card.purchase': 'Ποσότητα αγοράς',
  'device.table.card.Remain': 'Ποσότητα που απομένει',
  'device.table.card.time': 'Ώρα Χρήσης',
  'device.table.card.purchasetime': 'Ωρα Αγοράς',
  'device.table.card.year': ' Έτος',
  'device.table.card.month': ' Μήνας',
  'device.table.card.date': ' Ημέρα',

  'device.modal.remark.title': 'Σχόλιο',
  'device.modal.remark.placeholder': 'Παρακαλώ εισάγετε σχόλιο',
  'device.modal.remark.tip':
    'Εάν εισάγετε την Εταιρεία, ο χειριστής κάτω από αυτό μπορεί να δει τις πληροφορίες της συσκευής',

  'device.notification.description.add': 'Η συσκευή προστέθηκε επιτυχώς',
  'device.notification.description.noneed': 'Η επιλεγμένη συσκευή δεν χρειάζεται να ενεργοποιηθεί',
  'device.notification.description.noend':
    'Η συσκευή μπορεί να ενεργοποιηθεί μόνο μετά από ένα μήνα σχεδόν και κοντά στην περίοδο ισχύος.',
  'device.notification.description.type.wrong':
    'Η επιλεγμένη συσκευή δεν συνάδει με τον τύπο συσκευής που αντιστοιχεί στον τρέχοντα κωδικό ενεργοποίησης και η λειτουργία ενεργοποίησης της συσκευής δεν μπορεί να ολοκληρωθεί',
  'device.notification.description.success': 'Η συσκευή ενεργοποιήθηκε με επιτυχία',
  'device.notification.description.failed': 'Αποτυχία ενεργοποίησης συσκευής',
  'device.notification.description.type':
    'Ο τύπος της επιλεγμένης συσκευής είναι διαφορετικός από αυτόν της τρέχουσας συσκευής. Επομένως, η συσκευή δεν μπορεί να αντικατασταθεί. Επιλέξτε μια συσκευή του ίδιου τύπου',
  'device.notification.description.code':
    'Η τρέχουσα συσκευή που θα προστεθεί είναι ίδια με τον επιλεγμένο αριθμό συσκευής. Επομένως, η συσκευή δεν μπορεί να αντικατασταθεί. Πρέπει να αντικαταστήσετε τη συσκευή με διαφορετικό αριθμό συσκευής',
  'device.notification.description.confirm':
    'Είστε βέβαιοι ότι θα αντικαταστήσετε την αρχική συσκευή; Μετά την αντικατάσταση, η αρχική συσκευή μπορεί να διαβάσει τα δεδομένα μόνο πριν από την αντικατάσταση, τα έγκυρα δεδομένα της νέας συσκευής θα ξεκινήσουν μετά την αντικατάσταση.',
  'device.notification.description.unsupport':
    'Αυτή η δυνατότητα δεν υποστηρίζεται από την τρέχουσα συσκευή πρόσβασης.',
  'device.notification.description.nosite':
    'Το πλάνο δεν περιέχει σημεία ελέγχου, ορίστε πρώτα σημεία ελέγχου.',
  'device.notification.description.noguard':
    'Δεν υπάρχει φύλακας για να γίνει λήψη. Παρακαλώ καταχωρήστε φύλακες.',
  'device.notification.description.noplan':
    'Δεν υπάρχει πλάνο για να γίνει λήψη. Παρακαλώ καταχωρήστε φύλακες.',
  'device.notification.description.clearbook':
    'Δεν έχει επιλεγεί εγγραφή για λήψη. Εάν δεν έχει επιλεγεί εγγραφή για λήψη, οι πληροφορίες του βιβλίου διευθύνσεων στη συσκευή θα διαγραφούν. Θέλετε να συνεχίσετε;',
  'device.notification.description.unselect': 'Ελέγξτε πρώτα τη συσκευή πριν την λειτουργία',
};
