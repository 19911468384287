export default {
  'site.title': 'Configuration du point de contrôle',
  'site.btn.batch': 'Lecture par lots',
  'site.btn.add': 'Ajouter un point de contrôle',
  'site.btn.addgps': 'Ajouter un point de contrôle GPS',
  'site.btn.addcoll': 'Ajouter un point de contrôle de collecte',
  'site.btn.edit': 'Modifier',
  'site.btn.del': 'Supprimer le point de contrôle',
  'site.btn.download': 'Télécharger les données',
  'site.btn.import': "Point de contrôle d'importation",
  'site.btn.export': "Point de contrôle d'exportation",
  'site.btn.assign': 'Attribuer du contenu',
  'site.btn.move': 'Point de contrôle de transfert',
  'site.btn.rnd': 'Points de contrôle assignés au hasard',
  'site.btn.rnd.del': 'Supprimer tous les points de contrôle',
  'site.modal.position': 'Position géographique',
  'site.table.code': 'ID de point de contrôle',
  'site.table.name': 'Nom du point de contrôle',
  'site.table.type': 'Type de point de contrôle',
  'site.table.longitude': 'Longitude',
  'site.table.latitude': 'Latitude',
  'site.table.range': "Plage d'erreur (m)",
  'site.table.voice': 'Configuration vocale',
  'site.table.site': 'Emplacement',
  'site.table.app.content': 'Titre du contenu',
  'site.table.app.des': 'Description du contenu',
  'site.table.app.submission': 'Méthode de soumission',
  'site.table.app.qr': 'QR Code',
  'site.table.app.bluetooth': 'Bluetooth',
  'site.table.app.em': 'Code EM',
  'site.table.app.single': 'Seul',
  'site.table.app.multiple': 'Plusieurs',
  'site.table.app.enter': 'Plusieurs',
  'site.table.app.colluser': 'Collectionneurs',
  'site.table.app.colltime': 'Temps de collecte',
  'site.table.voice.clear': "Voix claire dans l'appareil",
  'site.table.voice.clear.success': 'Voix effacée avec succès',
  'site.table.voice.clear.failed': 'Échec de la suppression de la voix',
  'site.table.voice.clear.confirm': 'Confirmez pour supprimer la voix ?',
  'site.table.edit': 'Modifier',
  'site.input.site.placeholder': 'Veuillez saisir le nom du point de contrôle',
  'site.input.code.placeholder': "Veuillez saisir l'ID du point de contrôle",
  'site.Transfer.Unselected': 'Voix non sélectionnées',
  'site.Transfer.selected': 'Voix sélectionnées',
  'site.headerTitle.GPS': 'Tous les points de contrôle GPS',
  'site.headerTitle.All': 'Liste des points de contrôle voix et GPS configurés',
  'site.notification.filtercode':
    'Les enregistrements avec des codes de point de contrôle en double ont été filtrés',
  'site.notification.filtername':
    'Les enregistrements avec des noms de point de contrôle en double ont été filtrés',
  'site.notification.download.success': 'Téléchargement des données réussi',
  'site.notification.mostsite':
    'Pas plus de 1000 points de contrôle ne pouvaient être sélectionnés',
  'site.notification.mostgpssite':
    'Pas plus de 500 points de contrôle GPS ne pouvaient être sélectionnés',
  'site.notification.delposition':
    "Êtes-vous sûr de vouloir supprimer les coordonnées de l'emplacement sur la carte?",
};
