export default {
  'user.title': 'Opérateur',
  'user.btn.add': 'Ajouter un opérateur',
  'user.btn.dept': "Voir l'entreprise",
  'user.btn.right': 'Attribuer une autorisation',
  'user.btn.init': 'Initialiser le mot de passe',
  'user.btn.home': 'Rentrer à la maison',
  'user.table.displayname': 'Nom de connexion',
  'user.table.username': "Nom de l'opérateur",
  'user.table.rolename': 'Rôle',
  'user.table.operate': 'Fonctionner',
  'user.table.edit': 'Éditer',
  'user.role.title': 'Attribuer une autorisation',
  'user.role.label': 'Liste des rôles',
  'user.role.message': 'Veuillez sélectionner un rôle',
  'user.input.username.placeholder': "Veuillez saisir le nom de l'opérateur",
  'user.input.displayname.placeholder': "Veuillez saisir le nom d'affichage",
  'user.input.email.placeholder': 'Veuillez saisir un e-mail',
  'user.btn.init.confirm':
    "Assurez-vous de restaurer le mot de passe d'origine? (mot de passe initial: 999)",

  'user.serial.title': 'Activation du système',
  'user.serial.activation': "Code d'activation",
  'user.serial.verification': 'Code de vérification',
  'user.serial.verification.placeholder': 'Veuillez saisir le Code de vérification',
  'user.app.count': 'Quantité',
};
