export default {
  confirm: '確定',
  cancel: '取消',
  back: '返回',
  'general.homeSwitch.title.old': '不習慣當前首頁，切換回舊版？',
  'general.homeSwitch.title.new': '想體驗一下全新設計的首頁？',
  'general.WePatrol.qr.download.text': '掃描下載巡檢寶App',
  'general.WePatrol.qr.use.text': '使用 [ {code} ] 作為注冊編碼注冊賬號並登錄',
  'general.WePatrol.qr.login.text': '掃描二維碼登錄App',
  'general.guest.alert':
    '當前為訪客模式，所展示數據為實時生成的非真實模擬演示數據，僅供浏覽使用...',
  'general.unhandled.alert': '當前系統內有 {count} 條數據正在等待被處理分析，請耐心等待...',
};
