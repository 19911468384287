export default {
  'login.title': 'Καλως ήλθατε',
  'login.subtitle': 'Έξυπνο Σύστημα Περιπολίας Φυλάκων',
  'login.type.login': 'Σύνδεση',
  'login.type.trial': 'Δοκιμαστική',
  'login.companyCode': 'Κωδικός εταιρίας',
  'login.companyCode.required': 'Ο κωδικός εταιρίας απαιτείται',
  'login.userName': 'Όνομα χρήστη',
  'login.userName.required': 'Το όνομα χρήστη απαιτείται',
  'login.password': 'Κωδικός',
  'login.password.required': 'Απαιτείται κωδικός',
  'login.remember': 'Υπενθύμιση κωδικού',
  'login.captcha': 'Captcha',
  'login.captcha.captchaRequired': 'Το Captcha απαιτείται',
  'login.guest': 'Guest',
  'login.submit': 'Σύνδεση',
  'login.submitapplicaiton': 'Υποβολή',
  'login.captcha.required': 'Ολοκληρώστε την επαλήθευση ανθρώπου-μηχανής',
  'login.trial.companyName': 'Επωνυμία εταιρίας',
  'login.trial.companyName.required': 'Εισάγετε επωνυμία εταιρίας',
  'login.trial.equipment.read': 'Ανάγνωση',
  'login.trial.equipment': 'Αρ.Συσκευής ',
  'login.trial.equipment.required': 'Ανάγνωση αριθμού συσκευής',
  'login.trial.contacts': 'Όνομα Επαφής',
  'login.trial.contacts.required': 'Εισάγετε όνομα επαφής',
  'login.trial.contacts.phone': 'Τηλέφωνο',
  'login.trial.contacts.phone.required': 'Εισάγετε τηλέφωνο',
  'login.trial.captcha': 'Κωδικός επιβεβαίωσης',
  'login.trial.captcha.required': 'Εισάγετε κωδικό επιβεβαίωσης',
  'login.trial.captcha.read': 'Αποστολή',
  'login.trial.city': 'Πόλη',
  'login.trial.address.en': 'Διεύθυνση',
  'login.trial.countryCode': 'Κωδικος χώρας',
  'login.trial.timeZone': 'Ζώνη ώρας',
  'login.trial.city.required': 'Εισάγετε Πόλη',
  'login.trial.address': 'Διεύθυνση εταιρίας',
  'login.trial.address.required': 'Εισάγετε διεύθυνση εταιρίας',
  'login.trial.success': 'Η εφαρμογή είναι επιτυχής',
  'login.trial.contacts.email': 'Email',
  'login.trial.contacts.email.required': 'Εισάγετε Email',
  'login.trial.alarm.mobile':
    'Ο κωδικός επαλήθευσης έχει σταλεί στο γραμματοκιβώτιό σας, ελέγξτε τον προσεκτικά',
  'login.trial.alarm.email':
    'Ο κωδικός επαλήθευσης έχει σταλεί στο γραμματοκιβώτιό σας, ελέγξτε τον προσεκτικά',
  'login.trail.alarm.email.not.receive': 'δεν έγινε λήψη;',
  'login.trial.alarm.email.spam':
    'Η αλληλογραφία μπορεί να αποκλειστεί από τον πάροχο αλληλογραφίας σας με λανθασμένη πολιτική ασφαλείας, ελέγξτε στα Ανεπιθύμητα',
  'login.trial.alarm.reg.mobile':
    'Τα στοιχεία του λογαριασμού έχουν σταλεί στο γραμματοκιβώτιό σας, ελέγξτε τα προσεκτικά',
  'login.trial.alarm.reg.email':
    'Τα στοιχεία του λογαριασμού έχουν σταλεί στο γραμματοκιβώτιό σας, ελέγξτε τα προσεκτικά',
  'login.trial.captcha.mobile.format':
    'Η μορφή του αριθμού κινητού τηλεφώνου είναι εσφαλμένη. Παρακαλώ εισάγετε πάλι',
  'login.trial.contacts.email.format':
    'Η μορφή του αριθμού γραμματοκιβωτίου είναι εσφαλμένη. Παρακαλώ εισάγετε πάλι',
  'login.trial.contacts.mobile.legal': 'Λάθος μορφή κινητού τηλεφώνου',
  'login.trial.contacts.email.legal': 'Λάθος μορφή γραμματοκιβώτιου',

  'login.app.title': 'CloudPatrol+ App',
  'login.app.subTitle': '「 Τέλεια διαχείριση περιπολίας 」',
  'login.app.desc1': 'Σκανάρετε το QR στα αριστερά για λήψη της εφαρμογής',
  'login.app.desc2': 'Σκανάρετε το QR στα δεξιά για σύνδεση στην εφαρμογή',
  'login.app.desc3': 'Χρήση ονόματος χρήστη και κωδικού για σύνδεση',

  'login.slogan': 'Σύστημα περιπολίας φυλάκων',
  'login.vaptcha.text': 'Το Plugin κάνει αρχικοποίηση...',

  'login.nocaptcha.loading': 'Φορτώνει...',
  'login.nocaptcha.guide': 'Σύρετε δεξιά για επιβεβαίωση',
  'login.nocaptcha.pass': 'Κωδικός',
  'login.nocaptcha.error':
    'Ωχ..., click <a href="javascript:__nc.reset()">here</a> για προσπάθεια πάλι',
  'login.nocaptcha.offline':
    'Σφλάλμα δικτύου, click <a href="javascript:__nc.reset()">here</a> για ανανέωση',

  'login.expired.exit.desc': 'Τα διαπιστευτήρια έληξαν, συνδεθείτε πάλι...',

  'login.companyCode.illegal': '΄Λάθος κωδικός εταιρίας',
};
