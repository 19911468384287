export default {
  'content.title': 'Content Setup',
  'content.btn.add': 'Add Content',
  'content.btn.site': 'Select Checkpoint',
  'content.btn.choice': 'Add Options',
  'content.label.choice': 'Content Options',
  'content.input.content.placeholder': 'Please input content',
  'content.input.des.placeholder': 'Please input content description',
  'content.input.choice.placeholder': 'Please input content options',
  'content.notification.description': 'Patrol content title already exists, please re-enter.',
  'content.notification.choice.blank':
    'When the submission method is single or multiple choice, the content options must be entered!',
};
