// import { stringify } from 'querystring';
import { history } from 'umi';
import {
  login,
  reloadAuth,
  getApplication,
  getCaptcha,
  getEmail,
  userLoginOut,
  getIP,
  getCity,
} from '@/services/login';
import { getPageQuery, getSoftwareVersion } from '@/utils/utils';
import {
  getProfile,
  setAuthority,
  setDevices,
  setDeviceFlag,
  setProfile,
  setToken,
} from '@/utils/profile';
import Auths, { guestAuths } from '@/utils/Auths';
import { message } from 'antd';
import i18n from '@/utils/i18n';

const LoginModel = {
  namespace: 'login',
  state: {
    // 用户身份：授权用户user, 临时游客guest，空用户
    // role: 'guest',
    // role: getProfile().userID ? 'user' : '',
    role: getProfile().role,
    userID: getProfile().userID,
    userName: getProfile().userName,
    displayName: getProfile().displayName,
    companyCode: getProfile().companyCode,
    companyName: getProfile().companyName,

    // gps？报警？巡检宝标志
    type: getProfile().type || {},
    clientIp: '',
    provinces: '',
    city: '',
  },
  effects: {
    *login({ payload }, { call, put }) {
      let response = '';
      try {
        response = yield call(login, payload);
      } catch (e) {
        if (e && e.error.resultCode === 10003) {
          // 验证码输入错误时刷新验证码
          payload.errcallback();
        }
        return;
      }
      // console.log('login', response);
      const { companyCode, password, remember } = payload;

      const loginRole = companyCode === 'guest' ? 'guest' : 'user';

      // const lastUser = getProfile().userID;

      yield put({
        type: 'changeLoginStatus',
        payload: {
          ...response,
          role: loginRole,
          password,
          remember,
          // guest用户不保存userID，以保证每次重新加载页面时，可以区分是否是已授权登陆用户
          userID: loginRole === 'guest' ? 0 : response.userID,
          modules: loginRole === 'guest' ? guestAuths : response.modules,

          devices: response.devices,
          deviceFlag: response.deviceFlag,
        },
      });

      if (getSoftwareVersion() === 'cloud' || getSoftwareVersion() === 'M1.0') {
        if (
          companyCode.toLowerCase() === 'syjwm_kfb' ||
          companyCode.toLowerCase() === 'administrator'
        ) {
          history.replace('/company');
          return;
        }
      }

      history.replace('/');

      // TODO: 已经暂时移除了redirect功能

      // 当前登录用户和上次登录用户不一致时，不进行redirect
      // if (response.userID !== lastUser) {
      //   history.replace('/');
      //   return;
      // }

      // // TODO:
      // const urlParams = new URL(window.location.href);
      // const params = getPageQuery();
      // let { redirect } = params;

      // if (redirect) {
      //   const redirectUrlParams = new URL(redirect);
      //   if (redirectUrlParams.origin === urlParams.origin) {
      //     redirect = redirect.substr(urlParams.origin.length);
      //     if (redirect.match(/^\/.*#/)) {
      //       redirect = redirect.substr(redirect.indexOf('#') + 1);
      //     }
      //   } else {
      //     window.location.href = '/';
      //     return;
      //   }
      // }
      // history.replace(redirect || '/');
    },

    *loginSilent({ payload }, { call, put }) {
      const response = yield call(reloadAuth);
      // console.log('loginSilent', response);
      yield put({
        type: 'changeLoginStatus',
        payload: {
          ...response,
          role: 'user',
          password: getProfile().password,
          remember: getProfile().remember,
          userID: response.userID,
          modules: response.modules,

          devices: response.devices,
          deviceFlag: response.deviceFlag,
        },
      });
      const { callback } = payload;
      if (callback && typeof callback === 'function') {
        callback();
      }
    },

    *logout(_, { call }) {
      // 在登录页面统一移除token
      // removeToken();

      // const lastUser = getProfile();

      // yield put({
      //   type: 'changeLoginStatus',
      //   payload: { role: '' },
      // });

      const { redirect } = getPageQuery(); // Note: There may be security issues, please note

      if (window.location.pathname !== '/login' && !redirect) {
        history.replace({
          pathname: '/login',
        });
        // 屏蔽redirect功能
        // history.replace({
        //   pathname: '/login',
        //   // 登录用户是游客时，注销不保存redirect参数
        //   search: lastUser.role
        //     ? stringify({
        //         redirect: window.location.href,
        //       })
        //     : null,
        // });
      }

      yield call(userLoginOut);
    },
    *feachApplication({ payload }, { call }) {
      const response = yield call(getApplication, payload);
      if (response !== 'success') {
        message.success(response);
        return false;
      }
      message.success(i18n('login.trial.alarm.reg.mobile'), 2, () => {
        window.location.href = '/';
      });
      return true;
    },
    *feachCaptcha({ payload }, { call }) {
      const { callback, ...rest } = payload;
      const response = yield call(getCaptcha, rest);
      if (response !== 'success') {
        message.success(response);
        return false;
      }
      message.success(i18n('login.trial.alarm.mobile'), 5);
      if (callback && typeof callback === 'function') {
        callback();
      }
      return true;
    },
    *feachEmail({ payload }, { call }) {
      const { callback, ...rest } = payload;
      const response = yield call(getEmail, rest);
      if (response !== 'success') {
        message.success(response);
        return false;
      }
      message.success(i18n('login.trial.alarm.email'), 5);
      if (callback && typeof callback === 'function') {
        callback();
      }
      return true;
    },
    *feachGetIP({ payload }, { call, put }) {
      const response = yield call(getIP, payload);
      yield put({
        type: 'saveGetIP',
        payload: response.ip,
      });
    },
    *feachGetCity({ payload }, { call, put }) {
      const response = yield call(getCity, payload);
      yield put({
        type: 'saveGetCity',
        payload: response.content,
      });
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      const { modules, devices, token, deviceFlag, ...profile } = payload;
      const { role } = profile;
      if (token) setToken(token);
      // 不用toString，保存后自动转换为string
      if (modules) {
        // 如果登录用户是 admin 的话，手动加入BI大屏权限
        // TODO: 后续通过权限配置、接口返回进行规范，不再手动处理；
        // TODO: 目前暂定仅有 非 访客（访客用户已默认在guestAuths加入BI权限）、admin 用户、且支持gps、报警、地图的用户有查看权限
        if (
          role !== 'guest' &&
          profile.userName === 'admin' &&
          // profile.type?.gps &&
          profile.type?.alarm &&
          profile.type?.map
        )
          modules.push(Auths.rBI);
        setAuthority(modules);
      }

      setProfile(profile);
      if (devices) setDevices(devices);
      if (deviceFlag) setDeviceFlag(deviceFlag);
      return {
        ...state,
        ...profile,
      };
    },
    saveGetIP(state, { payload }) {
      return {
        ...state,
        clientIp: payload,
      };
    },
    saveGetCity(state, { payload }) {
      return {
        ...state,
        provinces: payload.address_detail.province,
        city: payload.address_detail.city,
      };
    },
  },
};
export default LoginModel;
