export default {
  'guard.title': 'Ρύθμιση φύλακα',
  'guard.btn.batch': 'Μαζική ανάγνωση',
  'guard.btn.add': 'Προσθήκη Φύλακα',
  'guard.btn.edit': 'Επεξεργασία Φύλακα',
  'guard.btn.del': 'Διαγραφή Φύλακα',
  'guard.btn.import': 'Εισαγωγή Φύλακα',
  'guard.btn.export': 'Εξαγωγή Φύλακα',
  'guard.table.code': 'ID Φύλακα',
  'guard.table.name': 'Όνομα Φύλακα',
  'guard.table.operate': 'Χειρισμός',
  'guard.table.edit': 'Επεξεργασία',
  'guard.app.device': 'Λίστα Συσκευών',
  'guard.app.placeholder': 'Παρακαλώ επιλέξτε συσκευή',
  'guard.finger.name': 'Δακτυλικό Αποτύπωμα',
  'guard.finger.coll': 'Συλλογή Δακτ.Αποτυπωμάτων',
  'guard.finger.coll.success': 'Επιτυχής συλλογή δακτ.αποτυπωμάτων',
  'guard.finger.coll.failed': 'Αποτυχία συλλογής δακτ.αποτυπωμάτων',
  'guard.finger.download': 'Λήψη δακτ.αποτυπωμάτων',
  'guard.finger.download.success': 'Επιτυχής λήψη δακτ.αποτυπωμάτων',
  'guard.finger.download.failed': 'Αποτυχία λήψης δακτ.αποτυπωμάτων',
  'guard.finger.download.deldata':
    'Η λήψη του δακτυλικού αποτυπώματος απέτυχε. Πριν πραγματοποιήσετε λήψη του δακτυλικού αποτυπώματος, διαγράψτε τα δεδομένα στη συσκευή.',
  'guard.finger.download.nofinger': 'Δεν υπάρχουν πληροφορίες δακτυλικών αποτυπωμάτων για λήψη.',
  'guard.input.guard.placeholder': 'Παρακαλώ εισάγετε όνομα φύλακα',
  'guard.finger.onlyOneLine': 'Μπορεί να γίνει επεξεργασία μόνο μιας γραμμής τη φορά',
};
