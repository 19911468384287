export default {
  'dashboard.charts': '圖表',
  'dashboard.charts.title.trend': '合格率趨勢',
  'dashboard.charts.title.rank': '合格率排行榜',
  'dashboard.charts.dept.placeholder': '請選擇區域',
  'dashboard.charts.rank.dept': '區域',
  'dashboard.charts.rank.plan': '計劃',
  'dashboard.charts.rank.guard': '人員',
  'dashboard.charts.rank.site': '地點',
  'dashboard.charts.date.today': '本日',
  'dashboard.charts.date.week': '本周',
  'dashboard.charts.date.month': '本月',
  'dashboard.charts.orderby.asc': '升序',
  'dashboard.charts.orderby.desc': '降序',
  'dashboard.statistics.type': '類型',
  'dashboard.statistics.type.qualified': '合格',
  'dashboard.statistics.type.unqualified': '漏檢',
  'dashboard.statistics.type.todo': '待巡',
  'dashboard.statistics.columnes.count': '數量',
  'dashboard.statistics.columnes.rate': '占比',
  'dashboard.statistics.title.percent': '今日合格率',
  'dashboard.latestHistories.columnes.site': '地點',
  'dashboard.latestHistories.columnes.guard': '人員/設備',
  'dashboard.latestHistories.columnes.info': '事件',
  'dashboard.latestHistories.columnes.remark': '備注',
  'dashboard.latestHistories.columnes.time': '時間',
  'dashboard.latestHistories.columnes.extra': '媒體',
  'dashboard.latestHistories.title.trend': '近期數據上傳趨勢',
  'dashboard.latestAlarms.title.alarmData': '近期報警',
  'dashboard.latestLogs.columnes.action': '界面 & 操作',
  'dashboard.latestLogs.columnes.time': '時間',
  'dashboard.latestLogs.columnes.reader': '設備',
  'dashboard.latestLogs.columnes.user': '用戶',
  'dashboard.latestLogs.title.log': '近期日志',
  'dashboard.card.extra.refresh': '刷新數據',
  'dashboard.card.extra.history': '跳轉到曆史數據界面',
  'dashboard.card.extra.alarm': '跳轉到報警數據界面',
  'dashboard.card.extra.log': '跳轉到日志數據界面',
};
