/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification } from 'antd';
import businessErrorCodes from './exceptions';
import { getToken } from '@/utils/profile';
import { getLocale, history } from 'umi';
import i18n from '@/utils/i18n';
import { stringify } from 'qs';

const codeMessage = {
  400: 'exception.400',
  401: 'exception.401',
  403: 'exception.403',
  404: 'exception.404',
  500: 'exception.500',
  502: 'exception.502',
  503: 'exception.503',
  504: 'exception.504',
};

let handleHttpErrorTimer = null;
/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { resultCode, response } = error;
  if (resultCode) {
    const knownError = businessErrorCodes[resultCode];
    notification.error({
      message: i18n('exception.fail'),
      description: knownError ? i18n(knownError) : i18n('exception.unknow'),
    });
    // eslint-disable-next-line no-throw-literal
    throw { type: 'business', error };
  }

  if (!handleHttpErrorTimer && response && response.status) {
    // 为了防止同时访问多个接口，导致连续返回错误的问题出现
    handleHttpErrorTimer = setTimeout(() => {
      handleHttpErrorTimer = null;
    }, 2000);
    // const errorText = codeMessage[response.status] || response.statusText;
    const knownError = codeMessage[response.status];
    const errorText = knownError ? i18n(knownError) : response.statusText;
    // eslint-disable-next-line no-unused-vars
    const { status, url } = response;

    if (status === 401) {
      // 超时后，回退到Login，并根据路径参数唤醒超时通知
      // 正常情况下直接在这里进行通知过期提示就好了，但是由于后台相关旧页面无法触发这里的401，所以暂时采用回退登录页面传递url参数的方式
      // TODO: 后台session失效后过滤器处理相同，后期页面全部集成完毕后进行统一；
      history.replace('/login?code=401');
    } else {
      notification.error({
        // message: `请求错误 ${status}: ${url}`,
        // message: `HTTP ${status}`,
        message: i18n('exception.fail'),
        description: errorText,
      });
    }
  } else if (!response) {
    notification.error({
      message: i18n('exception.network'),
      description: i18n('exception.network.desc'),
    });
  }
  // eslint-disable-next-line no-throw-literal
  throw { type: 'request', error };
  // throw error;
};
/**
 * 配置request请求时的默认参数
 */

const request = extend({
  errorHandler,
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// eslint-disable-next-line no-unused-vars
request.interceptors.response.use(async (response, options) => {
  if (response.status === 200) {
    if (options.responseType === 'blob') {
      // return response.body;
      return response.blob().then((blob) => blob);
    }
    const json = await response.json();
    if (json.resultCode === 0) {
      if (json.data) return json.data;
      return json;
    }
    if (json.ip) {
      return json;
    }
    if (json.content) {
      return json;
    }
    throw json;
  }
  return response;
});

// request.use(async (ctx, next) => {
//   const { req } = ctx;
//   const { url } = req;
//   if(url.indexOf('map') >= 0 || url.indexOf('area') >= 0) {

//     ctx.req.url = `api/${url}`;
//   } else {
//     ctx.req.url = `${getRole() === 'user' ? 'api' : 'mock'}/${url}`;
//   }

//   await next();
// });

request.interceptors.request.use(async (url, options) => {
  const { headers } = options;
  return {
    url,
    options: {
      ...options,
      headers: {
        ...headers,
        token: getToken(),
        locale: getLocale(),
      },
    },
  };
});

export function download(url, params, filaname) {
  return new Promise((resolve, reject) => {
    request(`${url}?${stringify(params)}`, {
      responseType: 'blob',
    })
      .then(
        (res) => {
          // 返回的不是blob数据，而是json，代表出错了
          if (res.type === 'application/json') {
            // 读取内容，转为json进行错解析
            const reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener('loadend', () => {
              const resErrJson = JSON.parse(reader.result);
              // TODO: 这里没有本地化解析错误
              // const knownError = ExceptionCodes[resErrJson.resultCode];
              notification.error({
                // message: `失败了！`,
                // description: knownError || `请求失败：${resErrJson.msg}`,
                message: i18n('common.message.prompt'),
                // description: knownError || `${resErrJson.msg}`,
                description: `${resErrJson.msg}`,
              });
              reject(resErrJson);
            });
          } else {
            // 正常返回blob数据，准备进行下载;
            const blob = new Blob([res], {
              //   //这么写居然同时支持了excel，pdf和csv？居然还支持zip？？？
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            });
            // 针对不同浏览器，下载方式是不一样的
            // refer to : https://stackoverflow.com/questions/37522011/permission-denied-for-clicking-link-in-internet-explorer-11
            // IE
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, filaname);
            } else {
              // Chrome,Firefox,etc...
              const link = document.createElement('a');
              link.style.display = 'none';
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute('download', filaname);
              document.body.appendChild(link);
              // TODO: 上方链接中说明在firefox中不支持click()?需要改造HTMLElment.prototype，
              // 实际测试中支持，后期再进行观察
              link.click();
            }
            resolve(res);
          }
        },
        (err) => {
          // console.log('download errr', err);
          reject(err);
        },
      )
      .catch((e) => {
        console.log('download error', e);
      });
  });
}

export default request;
