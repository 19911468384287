export default {
  'event.title': 'Event Setup',
  'event.btn.add': 'Add Event',
  'event.btn.import': 'Import Event',
  'event.btn.export': 'Export Event',
  'event.table.code': 'Event ID',
  'event.table.name': 'Event Name',
  'event.table.simple': 'Simple Event',
  'event.table.common': 'Common Event',
  'event.input.code.placeholder': 'Please input event ID',
  'event.input.name.placeholder': 'Please input event name',
};
