export default {
  'device.title': 'Configuration des paramètres',
  'device.system': 'Configuration des paramètres système',
  'device.device': "Configuration des paramètres de l'appareil",
  'device.modal.title': 'Rapide',
  'device.modal.ok': "D'ACCORD",
  'device.modal.edit': 'Modifier',
  'device.modal.save': 'Sauver',
  'device.modal.del': 'Supprimer',
  'device.modal.cancel': 'Annuler',
  'device.modal.operate': 'Fonctionner',
  'device.modal.setsuccess': 'Configuration réussie',
  'device.modal.seterr': 'Échec de la configuration',
  'device.modal.delconfirm': 'Êtes-vous sûr de vouloir supprimer les données sélectionnées?',
  'device.modal.delsuccess': 'Supprimé avec succès.',
  'device.modal.delerr': 'Échec de la suppression',
  'device.system.basic': 'Paramètre de base',
  'device.system.basic.event': 'Événement de sortie',
  'device.system.basic.cycle': 'Régime spécial',
  'device.system.basic.cost': 'Mot de passe',
  'device.system.basic.cost.des':
    'Indique - t - on la durée prévue de chaque patrouille dans le rapport de patrouille?',
  'device.system.basic.stay': 'Temps restant',
  'device.system.basic.stay.des': 'Indique si le temps de séjour est indiqué dans le rapport',
  'device.system.basic.order': 'Ordre des patrouilles',
  'device.system.basic.date': 'Formate de jujube',
  'device.system.basic.enable': 'Activer',
  'device.system.basic.disenable': 'Désactiver',
  'device.device.btn.parameter': 'Paramètres du périphérique. Installateur',
  'device.device.btn.parameter.remote': 'Paramètres du périphérique distant. Installateur',
  'device.device.btn.delalldevicedata': "Confirmer que les données sont effacées de l'appareil?",
  'device.device.btn.delalldeviceclock': "Confirmer la suppression de l'alarme dans l'appareil",
  'device.device.btn.deldevicedata': 'Effacer les données du périphérique',
  'device.device.btn.conn': 'Paramètres de communication. Installateur',
  'device.device.btn.delfinger': 'Empreintes claires',
  'device.device.btn.init': 'Initialisation du périphérique',
  'device.device.btn.initconfirm':
    "Êtes - vous sûr de vouloir restaurer l'appareil aux paramètres de l'usine?",
  'device.device.MsgNoReader': "Veuillez Connecter l'appareil.",
  'device.device.MsgNoFount': 'Aucun appareil trouvé, veuillez confirmer.',
  'device.device.MsgWriteFailed': "Échec de l'écriture",
  'device.device.MsgOpenFailed': "Impossible d'ouvrir le périphérique",
  'device.device.MsgReadFailed': 'Impossible de lire les données.',
  'device.device.MsgConnFailed': 'Échec de la connexion du périphérique.',
  'device.device.MsgTypeFailed': 'Mauvais type de périphérique',
  'device.device.noparam': "Le périphérique actuel n'a pas besoin de définir les paramètres",
  'device.device.init':
    "Êtes - vous sûr de vouloir restaurer l'appareil aux paramètres de l'usine?",
  'device.system.init': 'Initialisation du système',

  'device.btn.download.all': 'Télécharger toutes les données',
  'device.btn.download.select': 'Télécharger les données sélectionnées',

  'device.guardname': 'Nom du garde',
  'device.guardname.place': 'Veuillez saisir un nom',
  'device.guardname.des': "Afficher à l'écran",

  'device.clock': "Réglage de l'horloge",
  'device.clock.sn': 'Non',
  'device.clock.name': 'Horloge',
  'device.clock.add': 'Ajouter une horloge',
  'device.clock.del': "Supprimer l'horloge",
  'device.clock.down': 'Télécharger',
  'device.clock.delDown': 'Effacer le téléchargement',
  'device.clock.exittime': "Le temps d'alarme entré existe déjà, veuillez entrer à nouveau",
  'device.clock.nulltime': "Veuillez sélectionner l'heure d'horloge",
  'device.clock.max':
    "L'alarme ne peut télécharger que jusqu'à 200 groupes, veuillez sélectionner à nouveau",

  'device.flashlight.closemode': 'Mode off',
  'device.flashlight.closemode.dafault': 'Défaut de paiement',
  'device.flashlight.closemode.20': 'Éteignez automatiquement la lampe de poche après 20 secondes.',
  'device.flashlight.closemode.auto': 'Fermeture automatique',
  'device.flashlight.closemode.manual': 'Fermeture manuelle',
  'device.flashlight.closemode.closetime': 'Heure de fermeture',

  'device.alarm.title': "Bouton d'alarme",
  'device.alarm.choice1': "Appuyez sur le bouton pendant 1 seconde pour envoyer l'alarme",
  'device.alarm.choice2': "Appuyez sur le bouton pendant 2 secondes pour envoyer l'alarme",
  'device.alarm.choice3': "Appuyez sur le bouton pendant 3 secondes pour envoyer l'alarme",
  'device.alarm.choice4': "Appuyez sur le bouton pendant 4 secondes pour envoyer l'alarme",
  'device.alarm.choice5': "Appuyez sur le bouton pendant 5 secondes pour envoyer l'alarme",
  'device.alarm.choice6': "Appuyez sur le bouton pendant 6 secondes pour envoyer l'alarme",
  'device.alarm.choice7': "Appuyez sur le bouton pendant 7 secondes pour envoyer l'alarme",
  'device.alarm.choice8': "Appuyez sur le bouton pendant 8 secondes pour envoyer l'alarme",
  'device.alarm.choice9': "Appuyez sur le bouton pendant 9 secondes pour envoyer l'alarme",
  'device.alarm.choice10': "Appuyez sur le bouton pendant 10 secondes pour envoyer l'alarme",
  'device.alarm.choice11': "Appuyez sur le bouton pendant {count} secondes pour envoyer l'alarme",
  'device.shuttype': 'Mode arrêt',
  'device.shuttype.auto': 'Fermeture manuelle',
  'device.shuttype.manual': 'Fermeture automatique',
  'device.shuttype.time': "Temps d'arrêt",
  'device.shuttype.timeandunit': "Temps d'arrêt (minutes)",
  'device.shuttype.timeandunits': "Temps d'arrêt (secondes)",
  'device.shuttype.autotime': 'Heure de fermeture',
  'device.shuttype.unit': 'Unité',
  'device.shuttype.unit.minute': 'Minutes',
  'device.shuttype.unit.seconds': 'Secondes',

  'device.screen.resolution': 'Résolutions',
  'device.screen.recordtime': "Temps d'enregistrement (secondes)",
  'device.screen.number': 'SMS Center Number',

  'device.drop.meter1': "Chute libre d'un mètre",
  'device.drop.meter2': 'Chute libre de deux mètres',
  'device.drop.meter3': 'Chute libre de trois mètres',

  'device.step.state': 'État du podomètre',
  'device.impact.state': "État de l'alarme de collision",
  'device.impact.incline': "État de l'alarme d'inclinaison",
  'device.impact.incline.param1': "Paramètre d'alarme d'inclinaison 1",
  'device.impact.incline.param2': "Paramètre d'alarme d'inclinaison 2",
  'device.impact.incline.param3': "Paramètre d'alarme d'inclinaison 3",
  'device.impact.incline.angle': 'Angle',
  'device.impact.condition': "Conditions d'alarme de collision",
  'device.impact.static.param1': "Paramètre d'alarme statique 1",
  'device.impact.static.param2': "Paramètre d'alarme statique 2",
  'device.impact.static.param3': "Paramètre d'alarme statique 3",
  'device.impact.static.sensitivity': 'Sensible',
  'device.static.condition': "État de l'alarme statique",

  'device.clock.func': 'Fonction horloge',
  'device.clock.h2.ipmode': 'Mode de communication',
  'device.clock.h2.dynamicip': 'IP dynamique',
  'device.clock.h2.staticip': 'IP statique',
  'device.clock.h2.ipaddress': 'Adresse IP',
  'device.clock.h2.subnet': 'Masque de sous - réseau',
  'device.clock.h2.gateway': 'Passerelle',
  'device.clock.h2.wifiname': 'Wifi name',
  'device.clock.h2.wifipwd': 'Mot de passe WiFi',
  'device.clock.h2.wififunc': 'Fonctions Wifi',
  'device.clock.nullwifiname': 'Veuillez saisir un nom WiFi',
  'device.clock.nullwifipwd': 'Veuillez saisir votre mot de passe WiFi',
  'device.clock.h2.interval': "Intervalle de rappel de l'alarme (secondes)",
  'device.clock.h2.times': "Numéro de rappel de l'alarme",
  'device.clock.h2.intervaltimes': 'Intervalle de temps (secondes)',

  'device.call.type': 'Rappel',
  'device.call.type.mute': 'Muet',
  'device.call.type.vibration': 'Vibrations',
  'device.call.type.ring': 'La bague',
  'device.call.type.both': 'Vibrations et vibrations',

  'device.voice.state': 'Conditions de volume',
  'device.voice.volume': 'Volume',
  'device.voice.volume.high': 'Haut',
  'device.voice.volume.middle': 'Intermédiaire',
  'device.voice.volume.low': 'Faible',

  'device.finger.state': 'Fonction des empreintes digitales',
  'device.finger.verifyone': "À propos d'une vérification",
  'device.finger.verifycard': 'Ouvert - vérification lors de la lecture des points de contrôle',
  'device.finger.stitching': 'Temps de suture des empreintes digitales',
  'device.finger.confirmdel': 'Confirmer la suppression de toutes les empreintes digitales ?',
  'device.devicedata.deldatafirst':
    "Impossible de supprimer l'empreinte digitale, veuillez supprimer les données de l'appareil avant de supprimer l'empreinte digitale!",
  'device.devicedata.confirmdel': "Confirmer que les données sont effacées de l'appareil?",
  'device.temperature.display': 'Mode température',
  'device.temperature.celsius': 'C',
  'device.temperature.fahrenheit': 'Fahrenheit',

  'device.gps.working': 'Mode de fonctionnement',
  'device.gps.patrol': 'Mode de patrouille',
  'device.gps.coll': 'Mode de collecte des points de contrôle',
  'device.gps.interval': 'Temps (secondes)',
  'device.gps.collgps': 'Temps de collecte des positions GPS',

  'device.conn.title': 'Paramètres de communication. Définir les paramètres',
  'device.conn.type': 'Contact',
  'device.conn.ip': 'Connexion par adresse IP',
  'device.conn.domain': 'Contact',
  'device.conn.domain.name': 'Domaine',
  'device.conn.port': 'Ville portuaire',
  'device.conn.isp': 'Fournisseur de services réseau',
  'device.conn.mobile': 'China Mobile Communications',
  'device.conn.unicom': 'China Unicom',
  'device.conn.otherisp': 'Autres FSI',
  'device.conn.user': 'Utilisateurs',
  'device.conn.pwd': 'Mot de passe',
  'device.send.mode': 'Mode de transmission',
  'device.send.auto': 'Envoi automatique des données',
  'device.send.manu': 'Envoi Manuel des données',
  'device.send.func': 'Envoyer le bouton',
  'device.send.func1': 'Appuyez sur le bouton pendant 1 seconde pour envoyer les données',
  'device.send.func2': 'Appuyez sur le bouton pendant 2 secondes pour envoyer les données',
  'device.send.func3': 'Appuyez sur le bouton pendant 3 secondes pour envoyer les données',
  'device.send.func4': 'Appuyez sur le bouton pendant 4 secondes pour envoyer les données',
  'device.send.func5': 'Appuyez sur le bouton pendant 5 secondes pour envoyer les données',
  'device.send.func6': 'Appuyez sur le bouton pendant 6 secondes pour envoyer les données',
  'device.send.func7': 'Appuyez sur le bouton pendant 7 secondes pour envoyer les données',
  'device.send.func8': 'Appuyez sur le bouton pendant 8 secondes pour envoyer les données',
  'device.send.func9': 'Appuyez sur le bouton pendant 9 secondes pour envoyer les données',
  'device.send.func10': 'Appuyez sur le bouton pendant 10 secondes pour envoyer les données',
  'device.send.func11': 'Appuyez sur le bouton pendant {count} secondes pour envoyer les données',

  'device.read.data.loading': 'Chargement...',
  'device.read.data.blank': "Aucune donnée dans l'appareil.",
  'device.read.data.success': "Après une lecture réussie, l'enregistrement total est: ",
  'device.timing.success': 'Le timing a été réussi avec le Code du périphérique: ',
  'device.timing.failed': 'Échec du timing',
  'device.read.failed': 'Échec de la lecture',
  'device.serverTime.error': "Impossible d'obtenir l'heure du serveur",
  'device.timing.devicetype': 'Type de matériel: ',

  'device.status.disconnect': 'Périphérique introuvable',
  'device.status.disconnect.desc': "Veuillez Connecter l'appareil via USB",

  'device.socket.stop': 'Le pilote de communication ne fonctionne pas, Veuillez démarrer',
  'device.socket.stop.or': ' Ou ',
  'device.socket.download': 'Télécharger',
  'device.socket.downloading':
    'Le pilote est téléchargé par le navigateur et peut être installé une fois terminé',

  'device.socket.exist': 'Le conducteur conduit',
  'device.socket.exist.desc':
    "Pilote téléchargé et en cours d'exécution, encore besoin de télécharger à nouveau?",

  'device.socket.busy': 'Le conducteur est occupé...',
  'device.socket.timeout': "Temps d'arrêt du conducteur...",

  'device.register.list': 'Enregistrer le type de périphérique',
  'device.register.list.delete':
    'Êtes - vous sûr de vouloir supprimer ce type de périphérique enregistré?',
  'device.unregister.title': 'Type de périphérique non enregistré',
  'device.unregister.content':
    'Type de périphérique non enregistré {type}, Inscrivez - vous maintenant?',

  'device.register.success': "Type d'appareil enregistré avec succès",

  'device.btn.add': 'Ajouter un appareil',
  'device.btn.active': "Activer l'appareil",
  'device.btn.replace': "Remplacer l'appareil",
  'device.btn.remark': 'Modifier la remarque',
  'device.btn.param': 'Réglage des paramètres',
  'device.btn.home': 'Rentrer à la maison',

  'device.table.device.code': 'Code appareil',
  'device.table.device.code.des': 'Veuillez saisir le Code du périphérique',
  'device.table.device.type': "Type d'appareil",
  'device.table.device.time': "Heure d'inscription",
  'device.table.device.account': 'Compte',
  'device.table.device.name': 'Nom',
  'device.table.device.extra': 'Remarque',
  'device.table.device.range': 'Heure de début et de fin',

  'device.table.card.purchase': "Quantité d'achat",
  'device.table.card.Remain': 'Quantité restante',
  'device.table.card.time': 'Utiliser le temps',
  'device.table.card.purchasetime': "Temps d'achat",
  'device.table.card.year': ' An',
  'device.table.card.month': ' Mois',
  'device.table.card.date': ' Jour',

  'device.modal.remark.title': 'Remarque',
  'device.modal.remark.placeholder': 'Veuillez saisir une remarque',
  'device.modal.remark.tip':
    "Si vous saisissez le nom de l'entreprise, l'opérateur sous ce peut voir les informations de l'appareil",

  'device.notification.description.add': 'Appareil ajouté avec succès',
  'device.notification.description.noneed': "L'appareil sélectionné n'a pas besoin d'être activé",
  'device.notification.description.noend':
    "L'appareil ne peut être activé qu'après un mois environ et proche de la période de validité.",
  'device.notification.description.type.wrong':
    "L'appareil sélectionné est incompatible avec le type d'appareil correspondant au code d'activation actuel et l'opération d'activation de l'appareil ne peut pas être terminée",
  'device.notification.description.success': 'Appareil actif avec succès',
  'device.notification.description.failed': "Échec de l'activation de l'appareil",
  'device.notification.description.type':
    "Le type de l'appareil sélectionné est différent de celui de l'appareil actuel. Par conséquent, l'appareil ne peut pas être remplacé. Sélectionnez un appareil du même type",
  'device.notification.description.code':
    "Le périphérique actuel à ajouter est le même que le numéro de périphérique sélectionné. Par conséquent, l'appareil ne peut pas être remplacé. Vous devez remplacer l'appareil par un autre numéro d'appareil",
  'device.notification.description.confirm':
    "Êtes-vous sûr de remplacer l'appareil d'origine ? Après le remplacement, l'appareil d'origine ne peut lire que les données avant le remplacement, les données valides du nouvel appareil commenceront après le remplacement.",
  'device.notification.description.unsupport':
    "Cette fonctionnalité n'est pas prise en charge par le périphérique d'accès actuel.",
  'device.notification.description.nosite':
    "Le plan n'a pas de point de contrôle, veuillez d'abord définir un point de contrôle.",
  'device.notification.description.noguard':
    "Aucune garde qui peut être téléchargée. Veuillez d'abord mettre la garde.",
  'device.notification.description.noplan':
    "Aucun plan téléchargeable. Veuillez d'abord mettre la garde.",
  'device.notification.description.clearbook':
    "Aucun enregistrement n'a été sélectionné pour le téléchargement. Si aucun enregistrement n'est sélectionné pour le téléchargement, les informations du carnet d'adresses de l'appareil seront effacées. Voulez-vous continuer cette opération?",
  'device.notification.description.unselect': "Veuillez d'abord vérifier l'appareil à utiliser",
};
