const proSettings = {
  navTheme: 'dark',
  theme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'side',
  contentWidth: 'Fixed',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  title: '巡检平台系统',
  pwa: false,
  iconfontUrl: '',
  headerRender: true,
};
export default proSettings;
