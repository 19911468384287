export default {
  'system.title': 'Ρυθμίσεις συστήματος',
  'system.event': 'Λειτουργία Συμβάντων',
  'system.event.des': 'Αν υποστηρίζεται λειτουργία συμβάντων',
  'system.plan': 'Ειδικό Πλάνο',
  'system.plan.des':
    'Το πλάνο μπορεί να εκτελείται περιοδικά, δηλαδή ημερήσια, εβδομαδιαία, μηνιαία',
  'system.patroltime': 'Χρόνος Περιπολίας',
  'system.patroltime.des':
    'Αν ο συνολικό χρόνος περιπολίας κάθε κύκλου θα θα εμφανίζεται στην αναφορά περιπολιών',
  'system.remaintime': 'Χρόνος που απομένει',
  'system.remaintime.des':
    'Εμφανίστε τον χρόνο επιθεώρησης του ίδιου σημείου επιθεώρησης στην προγραμματισμένη χρονική περίοδο στην τελική αναφορά',
  'system.Sequence': 'Ακολουθία περιπολίας',
  'system.Sequence.des':
    'Εάν θα καθοριστεί ότι κάθε σημείο ελέγχου πρέπει να επιθεωρηθεί με τη σειρά που ορίζεται στο πλάνο',
  'system.dst': 'Θερινή ώρα',
  'system.dst.des':
    'Αν χρησιμοποιείται θερινή ώρα, ο χρόνος που σχετίζεται με το πλάνο θα είναι έγκυρος την επόμενη μέρα',
  'system.timeformate': 'Μορφή Ημερομηνίας',
  'system.timeformate.des': 'Η μορφή ημερομηνίας που εμγφανίζεται στο σύστημα',
  'system.database.period': 'Κύκλος δημιουργίας backup βάσης δεδομένων',
  'system.database.period.des': 'Πόσο συχνά γίνεται back η βάση δεδομένων, μονάδα: ημέρα',
  'system.database.time': 'Χρόνος backup βάσης δεδομένων',
  'system.database.time.des':
    'Προσπαθήστε να επιλέξετε ώρα χαμηλής αιχμής για τη μεταφόρτωση δεδομένων',
  'system.database.path': 'Διαδρομή backup Βάσης δεδομένων',
  'system.database.path.des':
    'Παρακαλώ εισάγετε τη διαδρομή backup βάσης δεδομένων, για παράδειγμα: d:\\backup. Αν η διαδρομή είναι κενή η βάση δεδομένων δεν θα γίνει backup',
  'system.save': 'Αποθήκευση',
  'system.enable': 'Ενεργοποίηση',
  'system.disable': 'Απενεργοποίηση',
  'system.notification.prompt': 'Προτροπή',
  'system.notification.success': 'Επιτυχής αποθήκευση',
};
