export default {
  'query.condition.dept': '區域名稱：',
  'query.condition.guard': '人員名稱',
  'query.condition.site': '地點名稱',
  'query.condition.event': '事件名稱',
  'query.condition.plan': '計劃名稱',
  'query.condition.planguard': '計劃人員',
  'query.condition.device': '設備號碼',
  'query.condition.begin': '開始時間',
  'query.condition.end': '結束時間',
  'query.condition.time': '巡檢時間',
  'query.condition.alarmtype': '報警類型',
  'query.input.placeholder.site': '請選擇地點',
  'query.input.placeholder.guard': '請選擇人員',
  'query.input.placeholder.device': '請選擇設備',
  'query.input.placeholder.plan': '請選擇計劃',
  'query.input.placeholder.alarm': '請選擇報警類型',
  'query.history.title': '歴史數據',
  'query.history.btn.reanalysis': '重新分析',
  'query.app.remark': '內容及備註',
  'query.app.multimedia': '多媒體',
  'query.app.view': '檢視',
  'query.app.notes': '備註',
  'query.app.view.pict': '檢視圖片',
  'query.app.view.video': '檢視視訊',
  'query.app.view.audio': '檢視音頻',
  'query.app.options': '選項內容',
  'query.app.state': '狀態',
  'query.app.notentered': '未輸入',
  'query.app.notselected': '未選擇',
  'query.app.selected': '已選擇',
  'query.app.site.errspan': '當前點的經緯度誤差範圍超出規定範圍，該點可能不是有效的巡檢點',
  'query.app.site.time': '當前巡檢時間可能被修改過，請聯系巡檢人員以確認',
  'query.app.site.both':
    '當前點的經緯度誤差範圍超出規定範圍，該點可能不是有效的巡檢點，同時，當前巡檢時間可能被修改過，請聯系巡檢人員以確認',
  'query.alarm.title': '報警數據',
  'query.alarm.btn.del': '刪除數據',
  'query.alarm.type.all': '全部報警',
  'query.alarm.type.manual': '手動報警',
  'query.alarm.type.lowvoltage': '低電報警',
  'query.alarm.type.incline': '傾斜報警',
  'query.alarm.type.static': '靜止報警',
  'query.alarm.type.impact': '摔碰報警',
  'query.alarm.type.open': '開殼報警',
  'query.alarm.info': '報警信息',
  'query.alarm.time': '報警時間',
  'query.alarm.position': '報警位置',
  'query.omit.title': '漏檢數據',
  'query.omit.rangetime': '起止時間',

  'query.chart.title': '圖錶數據',
  'query.chart.table.plantimes': '計劃數量',
  'query.chart.table.arrivedtimes': '到達數量',
  'query.chart.table.omittimes': '漏檢數量',
  'query.chart.table.arrivedrate': '合格率',
  'query.chart.table.omitrate': '漏巡率',
  'query.chart.table.summary': '數據匯總',
  'query.chart.tab.data': '統計數據',
  'query.chart.tab.chart': '圖錶展示',
  'query.chart.tab.chart.column': '柱形圖',
  'query.chart.tab.chart.bar': '條形圖',
  'query.chart.tab.chart.line': '線形圖',
  'query.chart.tab.omit': '漏檢數據',
  'query.chart.condition.type': '統計類型',
  'query.chart.condition.dept': '按區域信息統計',
  'query.chart.condition.guard': '按人員信息統計',
  'query.chart.condition.site': '按地點信息統計',
  'query.chart.condition.plan': '按計劃信息統計',

  'query.raw.title': '原始數據',
  'query.raw.table.card': '卡號',
  'query.raw.table.type': '卡類型',
  'query.raw.table.name': '卡號名稱',
  'query.raw.table.unknow': '未知卡',
  'query.raw.table.event': '事件卡',
  'query.raw.table.guard': '人員卡',
  'query.raw.table.site': '地點卡',
  'query.raw.table.time': '打卡時間',
  'query.raw.btn.unknow': '未知卡轉換',
  'query.raw.btn.site': '轉為地點卡',
  'query.raw.btn.guard': '轉為人員卡',
  'query.raw.btn.event': '轉為事件卡',

  'query.log.title': '日誌數據',
  'query.log.table.operate': '操作界面',
  'query.log.table.content': '操作內容',
  'query.log.table.time': '操作時間',
  'query.log.table.user': '操作人',
  'query.log.form.dept': '區域設定',
  'query.log.form.guard': '人員設定',
  'query.log.form.site': '地點設定',
  'query.log.form.plan': '計劃設定',
  'query.log.form.read': '讀取數據',

  'query.calendar.january': '1月',
  'query.calendar.february': '2月',
  'query.calendar.march': '3月',
  'query.calendar.april': '4月',
  'query.calendar.may': '5月',
  'query.calendar.june': '6月',
  'query.calendar.july': '7月',
  'query.calendar.august': '8月',
  'query.calendar.september': '9月',
  'query.calendar.october': '10月',
  'query.calendar.november': '11月',
  'query.calendar.december': '12月',
  'query.calendar.year': '年',
  'query.calendar.year1': '年',
  'query.calendar.month': '月',

  'query.report.condition.type': '數據類型',
  'query.report.condition.all': '全部數據',
  'query.report.condition.normal': '合格數據',
  'query.report.condition.todo': '待巡數據',
  'query.report.table.alltimes': '全部數量',
  'query.report.table.patroltimes': '巡檢次數',
  'query.report.table.topatrol': '待巡數量',
  'query.report.btn.export.summary': '導出匯總錶',
  'query.report.btn.export.ordinary': '導出明細錶',
  'query.report.btn.export.summaryname': '報錶匯總數據',
  'query.report.btn.export.ordinaryname': '報錶明細數據',
  'query.report.table.step': '步數',
  'query.report.btn.del': '刪除巡檢報告',

  'query.history.analysis': '確定要對選定的記錄進行重新分析處理嗎？',
  'query.content.fold': '全部摺疊',
  'query.content.expand': '全部展開',
};
