export default {
  'login.title': '欢迎',
  'login.subtitle': '欢迎使用智能巡检管理系统',
  'login.type.login': '平台登录',
  'login.type.trial': '试用申请',
  'login.companyCode': '企业编码',
  'login.companyCode.required': '请输入企业编码',
  'login.userName': '用户名',
  'login.userName.required': '请输入用户名',
  'login.password': '密码',
  'login.password.required': '请输入密码',
  'login.captcha': '验证码',
  'login.captcha.captchaRequired': '请输入验证码',
  'login.remember': '记住密码',
  'login.guest': '访客模式',
  'login.submit': '登录',
  'login.submitapplicaiton': '提交',
  'login.captcha.required': '请拖动滑块完成人机验证',
  'login.trial.companyName': '企业名称',
  'login.trial.companyName.required': '请输入企业名称',
  'login.trial.equipment.read': '读取设备号',
  'login.trial.equipment': '设备信息',
  'login.trial.equipment.required': '请读取设备信息',
  'login.trial.contacts': '联系人姓名',
  'login.trial.contacts.required': '请输入联系人姓名',
  'login.trial.contacts.phone': '联系人电话',
  'login.trial.contacts.phone.required': '请输入联系人电话',
  'login.trial.captcha': '短信验证码',
  'login.trial.captcha.required': '请输入验证码',
  'login.trial.captcha.read': '获取验证码',
  'login.trial.city': '所在城市',
  'login.trial.city.required': '请选择所在城市',
  'login.trial.address': '公司地址',
  'login.trial.address.en': '公司地址',
  'login.trial.address.required': '请输入公司地址',
  'login.trial.countryCode': '国家代码',
  'login.trial.timeZone': '时区',
  'login.trial.success': '申请成功',
  'login.trial.contacts.email': '邮箱',
  'login.trial.contacts.email.required': '请输入邮箱',
  'login.trial.alarm.mobile': '验证码已发送至您的手机，请注意查收',
  'login.trial.alarm.email': '验证码已发送至您的邮箱，请注意查收',
  'login.trail.alarm.email.not.receive': '无法收到邮件？',
  'login.trial.alarm.email.spam':
    '如果长时间未收到邮件，可能是出于安全策略的问题被你的邮件服务器错误拦截，请去垃圾箱里查看...',
  'login.trial.alarm.reg.mobile': '账号信息已发送至您的手机，请注意查收',
  'login.trial.alarm.reg.email': '账号信息已发送至您的邮箱，请注意查收',
  'login.trial.captcha.mobile.format': '手机号格式不正确，请重新输入',
  'login.trial.contacts.email.format': '邮箱号格式不正确，请重新输入',
  'login.trial.contacts.mobile.legal': '不合法的手机格式',
  'login.trial.contacts.email.legal': '不合法的邮箱格式',

  'login.app.title': '云巡检+ App',
  'login.app.subTitle': '「 巡检管理，体验更 + 」',
  'login.app.desc1': '扫码左侧二维码下载App',
  'login.app.desc2': '扫描右侧二维码登录App',
  'login.app.desc3': '使用平台的用户名密码进行登录',

  'login.slogan': '专注巡检{year}年',
  'login.vaptcha.text': '人机验证插件初始化中...',

  'login.nocaptcha.loading': '加载中...',
  'login.nocaptcha.guide': '按住滑块，拖动到最右侧',
  'login.nocaptcha.pass': '验证通过',
  'login.nocaptcha.error': '哎呀，出错了，点击<a href="javascript:__nc.reset()">刷新</a>再来一次',
  'login.nocaptcha.offline': '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>',

  'login.expired.exit.desc': '登录已过期，请重新登录...',

  'login.companyCode.illegal': '非法的企业编码',
};
