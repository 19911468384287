export default {
  'header.guest': '游客',
  'header.welcome': 'Hi, 欢迎你',
  'header.modifyPassword': '修改密码',
  'header.logout': '退出登录',
  'header.read': '读取数据',
  'header.timing': '设备校时',
  'header.report': '巡检报告',
  'header.history': '历史数据',
  'header.chart': '统计图表',
  'header.fullScreen': '切换全屏',
  'header.theme': '切换主题',
  'header.theme.light': '亮色主题',
  'header.theme.dark': '暗色主题',
  'header.component.download': '通讯组件',

  'header.password.original': '原密码',
  'header.password.original.placeholder': '请输入原密码',
  'header.password.new': '新密码',
  'header.password.new.placeholder': '请输入新密码',
  'header.password.confirm': '确认密码',
  'header.password.confirm.placeholder': '请输入确认密码',
  'header.password.notMatch': '两次密码输入不同，请确认',
  'header.password.same': '原密码与新密码相同，无需更改密码',
  'header.password.blank': '密码不能为空格',

  'header.password.modify.success': '修改密码成功，将在5秒钟后重新登录系统',

  'header.device.connected': '设备已连接',
  'header.device.disconnected': '设备未连接',
};
