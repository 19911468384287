export default {
  'sound.title': 'Ρύθμιση Φων.Μηνυμάτων',
  'sound.no': 'Αρ.',
  'sound.name': 'Φωνητικό μήνυμα',
  'sound.name.placeholder': 'Παρακαλώ  εισάγετε Φωνητικό μήνυμα',
  'sound.dept.placeholder': 'Παρακαλώ επιλέξτε εταιρία',
  'sound.table.operate': 'Λειτουργίες',
  'sound.table.edit': 'Επεξεργασία',
  'sound.table.add': 'Προσθήκη Φωνητικών μηνυμάτων',
  'sound.table.home': 'Επιστροφή σε Αρχκό μενού',
  'sound.table.voice': 'Δημιουργία φωνής',
  'sound.table.clear': 'Διαγραφή φωνητικών μηνυμάτων από συσκευή',
  'sound.table.voice.success': 'Επιτυχής δημιουργία φων.μηνυμάτων!',
  'sound.table.voice.err': 'Αποτυχία δημιουργίας φων.μηνυμάτων',
  'sound.table.delete': 'Διαγραφή',
  'sound.table.selected': 'Επιλεγμένο',
  'sound.table.item': 'αντικείμενο',
  'sound.modal.delconfirm': 'Θέλετε να διαγράψετε τα επιλεγμένα αρχεία',
  'sound.table.deletesuccess': 'Επιτυχής διαγραφή',
  'sound.modal.ok': 'Αποθήκευση',
  'sound.modal.cancel': 'Ακύρωση',
  'sound.modal.error': 'Αποτυχία Λειτουργίας',
  'sound.message.loading.add': 'Προσθήκη...',
  'sound.message.loading.edit': 'Γίνεται ρύθμιση...',
  'sound.genvoice.loading': 'Φορτώνει...',
  'sound.genvoice.invalidDevice': 'Αυτη η συσκευή δεν υποστηρίζει φωνητικά μηνύματα...',
};
