export default {
  10000: 'request.10000',
  10001: 'request.10001',
  10002: 'request.10002',
  10003: 'request.10003',
  10004: 'request.10004',
  10005: 'request.10005',
  10006: 'request.10006',
  10007: 'request.10007',
  10008: 'request.10008',
  10009: 'request.10009',
  10010: 'request.10010',
  10011: 'request.10011',
  10012: 'request.10012',
  10013: 'request.10013',
  10014: 'request.10014',
  10015: 'request.10015',
  10016: 'request.10016',
};
