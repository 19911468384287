export default {
  'bi.title': 'Plate-forme visualisée de patrouille',
  'bi.guard': 'Garde',
  'bi.site': 'Site',
  'bi.source': 'La source',
  'bi.plan': 'Plan',
  'bi.device': 'Appareil',
  'bi.record': 'Record',

  'bi.time': 'Temps',
  'bi.no': 'Non',

  'bi.record.trend': 'Tendance du trafic de données',
  'bi.record.average': 'Moyen',
  'bi.record.upload': 'Télécharger le trafic',
  'bi.record.unit': ' ',

  'bi.record.list': 'Liste de trafic de données',

  'bi.qualified': 'Qualifié',
  'bi.unqualified': 'Omettre',
  'bi.todo': 'Faires',

  'bi.qualified.rate': 'Taux admissible',
  'bi.unqualified.rate': "Taux d'omission",

  'bi.rate.today': "Statistiques d'aujourd'hui",
  'bi.attendacne.rank': 'Rang de fréquentation',
  'bi.rate.trend': 'Tableau des statistiques',

  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',

  'bi.empty': 'Pas de données ici',

  'bi.largest.guide':
    "Veuillez ajuster la fenêtre pour maximiser la taille afin d'obtenir une meilleure expérience de l'écran BI",
  'bi.largest.ignore': 'Ignorer',
};
