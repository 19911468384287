export default {
  'user.title': '操作員信息',
  'user.btn.add': '增加操作員',
  'user.btn.dept': '查看區域',
  'user.btn.right': '權限分配',
  'user.btn.init': '恢複初始密碼',
  'user.btn.home': '返回首頁',
  'user.table.displayname': '登陸名',
  'user.table.username': '操作員姓名',
  'user.table.rolename': '所屬角色',
  'user.table.operate': '操作',
  'user.table.edit': '編輯',
  'user.role.title': '操作員權限分配',
  'user.role.label': '角色列表',
  'user.role.message': '請選擇角色',
  'user.input.username.placeholder': '請輸入操作員姓名',
  'user.input.displayname.placeholder': '請輸入登陸名',
  'user.input.email.placeholder': '請輸入Email',
  'user.btn.init.confirm': '確定要恢複成初始密碼嗎？（初始密碼：999）',

  'user.serial.title': '系統激活',
  'user.serial.activation': '激活碼',
  'user.serial.verification': '驗證碼',
  'user.serial.verification.placeholder': '請輸入驗證碼',
  'user.app.expand': 'app擴充',
  'user.app.count': '申請數量',
};
