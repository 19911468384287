export default {
  'common.table.batchdel': '批量刪除',
  'common.table.del': '刪除',
  'common.table.moveup': '上移記錄',
  'common.table.movedown': '下移記錄',
  'common.table.cancel': '取消選擇',
  'common.table.selected': '已選',
  'common.table.item': '項',
  'common.btn.success': '操作成功',
  'common.btn.download.success': '數據下載成功',
  'common.btn.download.failed': '數據下載失敗',
  'common.btn.confirm': '確定',
  'common.btn.cancel': '取消',
  'common.btn.close': '關閉',
  'common.btn.template': '下載模闆',
  'common.btn.import.label': '請選擇導入文件',
  'common.btn.export.excel': '導出Excel格式',
  'common.btn.export.pdf': '導出Pdf格式',
  'common.btn.import.title': '單擊上載',
  'common.btn.home': '返回首頁',
  'common.message.prompt': '提示',
  'common.placeholder.message': '請輸入',
  'common.device.nodata': '設備內無數據，請讀卡後重新上載',
  'common.notification.description.batchsuccess': '批量讀取成功，讀取的記錄數為：',
  'common.notification.description.records': '本次錄入的記錄數為：',
  'common.notification.description.nodata': '沒有滿足條件的數據，請重新選擇查詢條件',
  'common.notification.description.required': '此項為必填項',
  'common.notification.query.range': '查詢時間範圍不能超過60天，請重新選擇查詢條件',
};
