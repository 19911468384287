export default {
  'map.alarmcontentinfo.alarmdetail': 'Λεπτομέρειες Συναγερμών:',
  'map.alarmcontentinfo.unkonwdetail': 'Αγνωστες λεπτομέρειες',
  'map.alarmcontentinfo.iknow': 'διαχείριση',

  'map.devicecontentinfo.unkonwperson': 'Άγνωστος Φύλακας',
  'map.devicecontentinfo.track.timerange': 'Χρονικό εύρος αναπαραγωγής δεδομένων κομματιού',
  'map.devicecontentinfo.track.speed': 'Ταχύτητα(km/Ω)',
  'map.devicecontentinfo.track.begin': 'Αναπαραγωγή',

  'map.floatingtoolbar.track.replay': 'Επανάληψη',
  'map.floatingtoolbar.track.pause': 'Παύση',
  'map.floatingtoolbar.track.resume': 'Επανάληψη',
  'map.floatingtoolbar.track.clear': 'Καθαρισμός',
  'map.floatingtoolbar.track.resetlimit': 'Επαναφορά Κατάστασης',

  'map.device.deviceno': 'ID Συσκευής',
  'map.search.nocondition.msg': 'Παρακαλώ εισάγετε κριτήρια αναζήτησης!',
  'map.track.notime.msg': 'Επιλέξτε το χρονικό εύρος αναπαραγωγής κομματιού!',
  'map.track.empty': 'Δεν βρέθηκαν δεδομένα κομματιού',
  'map.site.sitename': 'Ονομασία Τοποθεσίας',
  'map.site.address': 'Διεύθυνση',
  'map.module.title': 'Παρακολούθηση',
  'map.sitecontentinfon.unknowdept': 'Άγνωστο Τμήμα',
  'map.sitecontentinfon.unknowperson': 'Άγνωστος Φύλακας',
  'map.sitecontentinfon.actionhistory': 'Ιστορικό',
  'map.topcomponent.select.plan': 'Παρακαλώ επιλέξτε Πλάνο',
  'map.topcomponent.device.onlinetime': 'Χρόνος Online (λεπ.)',
  'map.topcomponent.screen.full': 'Εναλλαγη σε Πλήρη Οθόνη',

  'map.realdata.table.deptname': 'Τμήμα',
  'map.realdata.table.sitename': 'Τοποθεσία',
  'map.realdata.table.guardname': 'Φύλακας',
  'map.realdata.table.guardcode': 'Συσκευή',
  'map.realdata.table.happentime': 'Ώρα',

  'map.realdata.changepage.old': 'Μετάβαση στον παλιό χάρτη οθόνης',
  'map.realdata.changepage.new': 'Μετάβαση στον νέο χάρτη οθόνης',
  'map.realdata': 'Δεδομένα Real-Time',

  'map.google.normal': 'Κανονική',
  'map.google.satellite': 'Δορυφορική',

  'map.videomodal.title': 'video',
  'map.audiomodal.title': 'ήχος',
  'map.button.close': 'κλείσιμο',

  'map.playback': 'Αναπαραγωγή κομματιού',
  'map.playback.over': 'Τέλος αναπαραγωγής κομματιού, τα χιλιόμετρα περιπολίας:',
  'map.playback.mile': 'μέτρο',
  'map.playback.timespan':
    'Η χρονική περίοδος αναπαραγωγής κομματιού δεν μπορεί να υπερβαίνει τις 2 ημέρες, επιλέξτε ξανά το εύρος ημερομηνιών',

  'map.load.timeout': 'Λήξη χρονικού ορίου φόρτωσης χάρτη, η σελίδα θα επαναφορτωθεί',

  'map.cluster.more': 'Κάντε μεγέθυνση για να δείτε περισσότερους δείκτες...',
  'map.site.add.confirm':
    'Είστε βέβαιοι ότι θέλετε να προσθέσετε όλα τα σημεία ελέγχου κάτω από το τρέχον επιλεγμένο σχέδιο στον χάρτη;',
  'map.site.del.confirm':
    'Είστε βέβαιοι ότι θέλετε να καταργήσετε όλα τα σημεία ελέγχου στο πλαίσιο του τρέχοντος επιλεγμένου σχεδίου από τον χάρτη;',
  'map.site.del.info': 'Παρακαλώ επιλέξτε ένα πλάνο πρώτα',
};
