export default {
  'user.title': 'Информация об операторе',
  'user.btn.add': 'Добавить оператора',
  'user.btn.dept': 'Область просмотра',
  'user.btn.right': 'Правильное назначение',
  'user.btn.init': 'Восстановить первоначальный пароль',
  'user.btn.home': 'Вернуться на домашнюю страницу',
  'user.table.displayname': 'Имя пользователя',
  'user.table.username': 'Имя оператора',
  'user.table.rolename': 'Собственная роль',
  'user.table.operate': 'Операция',
  'user.table.edit': 'Изменить',
  'user.role.title': 'Назначение прав оператора',
  'user.role.label': 'список ролей',
  'user.role.message': 'Выберите роль',
  'user.input.username.placeholder': 'Пожалуйста, введите имя оператора',
  'user.input.displayname.placeholder': 'Пожалуйста, введите имя пользователя',
  'user.input.email.placeholder': 'Пожалуйста, введите адрес электронной почты',
  'user.btn.init.confirm':
    'Вы уверены, что хотите восстановить исходный пароль? (Начальный пароль: 999)',

  'user.serial.title': 'активация системы',
  'user.serial.activation': 'активация системы',
  'user.serial.verification': 'код проверки',
  'user.serial.verification.placeholder': 'Введите код проверки',
  'user.app.count': 'количество',
};
