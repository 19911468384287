export default {
  'request.10000': '不存在的企业',
  'request.10001': '用户名或密码错误',
  'request.10002': '软件试用期已到期，请与管理员联系',
  'request.10003': '验证码输入错误',
  'request.10004': '企业名称已存在，请重新输入',
  'request.10005': '该手机号已注册，请勿重复操作',
  'request.10006': '原密码与新密码相同，不用更改密码',
  'request.10007': '当前用户不存在',
  'request.10008': '原密码输入错误',
  'request.10009': '当前用户已经登陆，不能重复登陆，请稍后再试',
  'request.10010': '当前登录用户数已超出系统允许的最大用户数量',
  'request.10011': '该邮箱已注册，请勿重复操作',
  'request.10012': '设备号已注册，请核查',
  'request.10013': '操作过于频繁，请1分钟后再试',
  'request.10014': '系统激活失败，请右键点击验证码以获取系统激活码',
  'request.10015': '当前用户尚未分配任何权限，请联系管理员设置权限后再登录系统',
  'request.10016': '请插入加密狗',
};
