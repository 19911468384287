import { readAlarms } from '@/services/alarm';

// TODO: 能否实现后端监听数据库表插入动作，从而取消轮询的监听方式重构相关监听？？？
const MonitorModel = {
  namespace: 'monitor',
  state: {
    alarms: [],
    points: [],
    alarmListVisible: false,

    records: [],

    unhandledCount: 0,
    unhandledAlertVisible: true,
  },
  effects: {
    *handleAlarms({ payload, callback }, { call, put }) {
      const { id, type } = payload;
      const response = yield call(readAlarms, id, type);
      if (response.resultCode === 0) {
        yield put({
          type: 'clearAlarms',
          payload,
        });
        callback(true);
      } else {
        callback(false);
      }
    },
  },
  reducers: {
    changeAlarmListVisible(state, { payload }) {
      return {
        ...state,
        alarmListVisible: payload,
      };
    },
    saveAlarms(state, { payload }) {
      return {
        ...state,
        alarms: payload,
      };
    },
    savePoints(state, { payload }) {
      return {
        ...state,
        points: payload,
      };
    },
    clearAlarms(state, { payload }) {
      const { id, type } = payload;
      const updatedAlarms = type === 1 ? [] : state.alarms.filter((item) => item.id !== id);
      return {
        ...state,
        alarms: updatedAlarms,
      };
    },

    saveRecords(state, { payload }) {
      return {
        ...state,
        records: payload,
      };
    },

    changeUnhandledAlertVisible(state, { payload }) {
      return {
        ...state,
        unhandledAlertVisible: payload,
      };
    },

    changeUnhandledCount(state, { payload }) {
      return {
        ...state,
        unhandledCount: payload,
      };
    },
  },
};
export default MonitorModel;
